import React, { Fragment } from "react";
import NavBar from "../NavBar/NavBar";
import "./Layout.css";
import { Container } from "react-bootstrap";

//https://stackoverflow.com/questions/61274798/how-to-display-an-image-in-full-screen-background-using-reactjs-and-css
const Layout = (props) => {
  return (
    <Fragment>
      {/* The navbar will show on all pages with page data below */}
      <NavBar />
      <main className="main">{props.children}</main>
      {/* The footer is fixed to the bottom of the page */}
      <footer className="fixed-bottom bg-muted text-dark">
        <Container className="p-0" fluid>
          <div className="bg-dark d-flex justify-content-around font-weight-bold text-white">
            Dissertation Project by Glenn Grainger - The Website is Published
            for Demonstration Purposes
          </div>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Layout;
