import React from "react";
import EditCampsiteForm from "../components/Campsite/EditCampsite/EditCampsiteForm";
import UpdateCampsiteImages from "../components/Campsite/EditCampsite/UpdateCampsiteImages";
import { Container, Row, Col, Alert } from "react-bootstrap";

const EditCampsite = () => {
  return (
    <React.Fragment>
      <Container fluid className="pr-3 pl-3">
        <h1 className="h1 mt-2">Edit Campsite</h1>
        <Row>
          <Col sm={7} className="border-right p-2 border-dark">
            <Alert variant="info">
              Save and make changes to your campsite listings. To save changes
              click update at the bottom of the form.
            </Alert>
            <EditCampsiteForm />
          </Col>
          <Col sm={5}>
            <Alert className="mt-2" variant="danger">
              Warning: All Image Changes Are Permanent and Take Effect
              Immediately
            </Alert>
            <UpdateCampsiteImages />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EditCampsite;
