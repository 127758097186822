import React from "react";
import RoutePointListItem from "./RoutePointListItem";

const RoutePointList = (props) => {
  return (
    <React.Fragment>
      <div className="h-75 overflow-auto">
        <ul className="list-group list-group-flush">
          {props.routePoints.map((routepoint) => (
            <RoutePointListItem key={routepoint.id} routepoint={routepoint} />
          ))}
          {/* If the routepoint list is empty show help information */}
          {props.routePoints.length == 0 && (
            <div className="lead">
              <br />
              <p>
                Add a location or click add campsite to show all the campsites
                on the map!
              </p>
              <br />
              <p>
                Click on a campsite to show its details and add it to the trip!
                Costs can be added to a route, and invites can be sent to other
                users to allow them to make changes to the route.
              </p>
              <br />
              <p>
                To view tourist attractions, toilets, parking, and tourist
                information, use the search box or click on a route point on the
                map.
              </p>
              <br />
              <p>
                Remove route points or view/update them to keep the trip up to
                date.
              </p>
            </div>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default RoutePointList;
