import React, { useEffect, useContext, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import AuthContext from "../store/auth-context";
import { Container } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
} from "react-bootstrap";
import ReactDOM from "react-dom";
import AddPostModal from "../components/Modal/Forum/AddPostModal";
import ForumPostList from "../components/Forum/ForumPostList";
import PaginationDisplay from "../components/UI/Pagination/PaginationDisplay";

const ForumTopic = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Access to URL parameters
  const params = useParams();
  //Will need to redirect
  const history = useHistory();
  //Modal Visibility State
  const [showAddThreadModal, setShowAddThreadModal] = useState(false);
  //Order selection state
  const [orderSelection, setOrderSelection] = useState("date_asc");
  const [displayOrderSelection, setDisplayOrderSelection] = useState("Recent");
  //Search input state
  const [searchInput, setSearchInput] = useState("");

  //State
  const [posts, setPosts] = useState();
  const [title, setTitle] = useState("Forum");

  //Pagination State
  const pageSize = 12;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  //Get topic on page load
  useEffect(() => {
    const getTopic = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/getTopic/${params.topicId}`,
          authCtx.config
        );
        setTitle(resp.data.title);
      } catch (error) {
        // Handle Error
        //Redirect to not found
        history.replace("/notfound");
        console.log(error.response);
      }
    };
    getTopic();
  }, [authCtx.config, history, params.topicId]);

  //Get posts when refresh toggled, search input changes, order selection changes or the page number changes
  useEffect(() => {
    const getPosts = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/posts/${params.topicId}?PageNumber=${pageNumber}&PageSize=${pageSize}&QuerySearch=${searchInput}&Order=${orderSelection}`,
          authCtx.config
        );
        setPosts(resp.data);
        //Store pagination metadata in response header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    getPosts();
  }, [
    authCtx.refresh,
    authCtx.config,
    params.topicId,
    searchInput,
    orderSelection,
    pageNumber,
  ]);

  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <AddPostModal
          topicId={params.topicId}
          show={showAddThreadModal}
          handleClose={() => setShowAddThreadModal(false)}
        />,
        document.getElementById("add-thread-modal")
      )}
      <Container fluid className="p-0 mt-2 " style={{ height: "88vh" }}>
        <div className="h-100 p-3">
          <Row>
            <Col>{title && <h1 className="h1">{title}</h1>}</Col>
            <Col>
              <p className="lead float-right">
                View, Add a Post or Reply to Others!
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={3}>
              <InputGroup className="mb-3">
                <FormControl
                  onChange={(event) => {
                    setSearchInput(event.target.value);
                    setPageNumber(1);
                  }}
                  placeholder="Search Title..."
                  aria-label="Recipient's username"
                  aria-describedby="title"
                  className="float-right"
                />
              </InputGroup>
            </Col>
            <Col sm={4}>
              <InputGroup className="mb-3">
                <InputGroup.Text>Order By </InputGroup.Text>

                <DropdownButton
                  as={InputGroup.Append}
                  variant="outline-secondary"
                  title={displayOrderSelection}
                  id="dropDown"
                >
                  <Dropdown.Item
                    onClick={(event) => {
                      setOrderSelection("date_asc");
                      setDisplayOrderSelection("Recent");
                    }}
                  >
                    Recent
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(event) => {
                      setOrderSelection("date_dsc");
                      setDisplayOrderSelection("Oldest");
                    }}
                  >
                    Oldest
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(event) => {
                      setOrderSelection("upvotes");
                      setDisplayOrderSelection("Most Upvoted");
                    }}
                  >
                    Upvotes
                  </Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </Col>
            <Col sm={5}>
              <Button
                onClick={() => setShowAddThreadModal(true)}
                variant="primary"
                className="float-right"
              >
                New Post
              </Button>
            </Col>

            <Col lg={12} className="border-top border-light">
              {posts && <ForumPostList posts={posts} />}
              <div className="d-flex justify-content-center">
                {paginationData && (
                  <PaginationDisplay
                    pagination={paginationData}
                    pageSelected={pageNumberHandler}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ForumTopic;
