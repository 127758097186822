import React from "react";
import RouteListItem from "./RouteListItem";

const GroupRouteList = (props) => {
  return (
    <React.Fragment>
      {props.routesAccess && (
        <ul className="w-100 h-100 overflow-auto list-group list-group-flush">
          {props.routesAccess.map((routeaccess) => (
            <RouteListItem
              key={routeaccess.route.id}
              route={routeaccess.route}
            />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default GroupRouteList;
