import React, { useState, useContext } from "react";
import { BsTrashFill } from "react-icons/bs";
import ReactDOM from "react-dom";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import { Col, Row } from "react-bootstrap";

toast.configure();

const UserListItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Toggle modal visibility
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const deleteUser = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/user/delete?username=${props.user.username}`,
        authCtx.config
      );
      //Reload API data
      authCtx.refreshData();
      //Toast Notification
      toast.success("User has been deleted", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Log Error
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirm}
          handleClose={() => setShowDeleteConfirm(false)}
          header={"Delete User"}
          message={
            props.user &&
            `Are you sure you want to delete user - ${props.user.username}? This action is irreversible.`
          }
          delete={() => deleteUser(props.user.username)}
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li key={props.user.username} className="list-group-item">
        <Row className="lead mt-2">
          <Col sm={4}>
            <p> {props.user.username}</p>
          </Col>
          <Col sm={3}>
            <p>
              {props.user.role === 0 && "user"}
              {props.user.role === 1 && "campsiteuser"}
              {props.user.role === 2 && "admin"}
            </p>
          </Col>
          <Col sm={3}>
            <p>{props.user.email}</p>
          </Col>
          {props.user.role !== 2 && (
            <Col sm={2}>
              <BsTrashFill
                size="0.79em"
                role="button"
                onClick={() => setShowDeleteConfirm(true)}
                className="text-danger float-right mt-2"
              />
            </Col>
          )}
        </Row>
      </li>
    </React.Fragment>
  );
};

export default UserListItem;
