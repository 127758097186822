import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { MdAttachMoney } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import CostsModal from "../Modal/Costs/CostsModal";
import ReactDOM from "react-dom";
import RouteUsersModal from "../Modal/RouteUsers/RouteUsersModal";

const RoutePointListHeader = (props) => {
  //Modal Visibility State
  const [showCostsModal, setShowCostsModal] = useState(false);
  const [showRouteUsersModal, setShowRouteUsersModal] = useState(false);
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <CostsModal
          show={showCostsModal}
          handleClose={() => setShowCostsModal(false)}
        />,
        document.getElementById("show-costs-modal")
      )}
      {ReactDOM.createPortal(
        <RouteUsersModal
          show={showRouteUsersModal}
          handleClose={() => setShowRouteUsersModal(false)}
          //Pass route data to the modal
          route={props.route}
        />,
        document.getElementById("show-route-users-modal")
      )}
      <Row className="p-3" style={{ maxHeight: "13%" }}>
        <Col sm={8}>
          <h3 className="h3">{props.route.title}</h3>
        </Col>
        <Col sm={2}>
          <Button
            variant="primary btn-sm"
            className="float-right"
            onClick={() => setShowRouteUsersModal(true)}
          >
            Users <HiUsers />
          </Button>
        </Col>
        <Col sm={2}>
          <Button
            variant="warning btn-sm"
            className="float-right"
            onClick={() => setShowCostsModal(true)}
          >
            Costs <MdAttachMoney />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RoutePointListHeader;
