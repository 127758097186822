import React, { useRef, useState, useEffect, useContext } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import Spinner from "react-bootstrap/Spinner";

toast.configure();

const AddPostModal = (props) => {
  //Global state
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //Image state
  const [imageList, setImageList] = useState([]);
  const [imageGalleryList, setImageGalleryList] = useState(null);
  //Refs
  const titleRef = useRef();
  const messageRef = useRef();
  const fileRef = useRef();
  //Validation state
  const [validationErrors, setValidationErrors] = useState({});
  const [fileValidationErrors, setFileValidationErrors] = useState();

  useEffect(() => {
    //Recreate the image gallery list when the image list changes
    const createImageGalleryList = () => {
      const arry = [];
      //Create the image list in the format accepted by the slideshow component
      imageList.forEach((image) => {
        arry.push({ original: URL.createObjectURL(image) });
      });
      setImageGalleryList(arry);
    };
    createImageGalleryList();
  }, [imageList]);

  const clearFileInput = () => {
    setImageList([]);
    fileRef.current.value = null;
  };

  const filesAddedHandler = (event) => {
    //store access to the image files
    setImageList([...event.target.files]);
  };

  const addPost = async (data) => {
    //Show loading spinner
    setIsLoading(true);
    //Reset validation errors
    setValidationErrors({});
    setFileValidationErrors();
    //Config using multipart form data to upload images
    const config = {
      headers: {
        Authorization: `Bearer ${authCtx.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const resp = await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/forum/post/add",
        data,
        config
      );
      //Toast Notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Hide spinner
      setIsLoading(false);
      authCtx.refreshData();
      //Close the modal
      props.handleClose();
    } catch (error) {
      // Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);

      error.response.data &&
        error.response.data.message &&
        setFileValidationErrors(error.response.data.message);
      //Hide loading spinner
      setIsLoading(false);
    }
  };

  const submitHandler = (event) => {
    //Prevent refresh
    event.preventDefault();
    const titleValue = titleRef.current.value;
    const messageValue = messageRef.current.value;

    //Create form data object and add data
    var formData = new FormData();
    formData.append("TopicId", props.topicId);
    formData.append("Title", titleValue);
    formData.append("Message", messageValue);
    imageList.forEach((image) => {
      formData.append("ForumImages", image);
    });
    addPost(formData);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">New Post</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control ref={titleRef} />
              {validationErrors.Title && (
                <span className="text-danger">{validationErrors.Title[0]}</span>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control ref={messageRef} as="textarea" rows={4} />
              {validationErrors.Message && (
                <span className="text-danger">
                  {validationErrors.Message[0]}
                </span>
              )}
            </Form.Group>

            <Form.Group>
              <Row>
                <Col sm={3}>
                  <Form.File
                    label="Add Images"
                    accept="image/*"
                    onChange={(event) => filesAddedHandler(event)}
                    ref={fileRef}
                    multiple
                  />
                  <Button
                    variant="danger btn-sm float-right mt-2"
                    onClick={() => clearFileInput()}
                  >
                    Clear
                  </Button>
                </Col>
                <Col sm={4}>
                  {imageGalleryList && (
                    <ImageGallery
                      items={imageGalleryList}
                      showThumbnails={false}
                      showPlayButton={false}
                      showFullscreenButton={false}
                    />
                  )}
                </Col>
                {fileValidationErrors && (
                  <span className="text-danger">{fileValidationErrors}</span>
                )}
              </Row>
            </Form.Group>
            <div className="font-weight-bold">
              Ensure the reply fulfils the community guidelines
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!isLoading && (
              <Button
                variant="secondary btn-sm"
                onClick={() => {
                  props.handleClose();
                  setValidationErrors({});
                  setFileValidationErrors();
                  clearFileInput();
                }}
              >
                Close
              </Button>
            )}

            {isLoading && (
              <Spinner
                animation="border"
                variant="primary"
                className="float-right"
              />
            )}
            {!isLoading && (
              <Button variant="primary btn-sm" type="submit">
                Add
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddPostModal;
