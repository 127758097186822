import React from "react";
import UserPosts from "./UserPosts";
import UserReplies from "./UserReplies";
import { Tabs, Tab } from "react-bootstrap";
import UserUpvotes from "./UserUpvotes";

const UserActivity = () => {
  return (
    <Tabs
      defaultActiveKey="posts"
      id="user-activity-tabs"
      className="mb-3 mt-3"
      fill
    >
      <Tab eventKey="posts" title="Posts" tabClassName="text-dark">
        <UserPosts />
      </Tab>
      <Tab eventKey="replies" title="Replies" tabClassName="text-dark">
        <UserReplies />
      </Tab>
      <Tab eventKey="upvotes" title="Upvoted" tabClassName="text-dark">
        <UserUpvotes />
      </Tab>
    </Tabs>
  );
};

export default UserActivity;
