import React, { useState } from "react";
import AddLocationModal from "../Modal/Route/AddLocationModal";
import ReactDOM from "react-dom";

const RoutePointListFooter = (props) => {
  //Modal Visibility State
  const [showLocationModal, setShowLocationModal] = useState(false);
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <AddLocationModal
          show={showLocationModal}
          handleClose={() => setShowLocationModal(false)}
          route={props.route}
          submitHandler={props.addLocationHandler}
        />,
        document.getElementById("add-location-modal")
      )}
      <div style={{ height: "12%" }}>
        {/* Change the button colour depeding on the cancel button state */}
        <button
          onClick={props.buttonClick}
          className={`btn btn-lg btn-block mt-3 ${
            !props.cancelButton ? "btn-primary" : "btn-danger"
          }`}
        >
          {!props.cancelButton ? "Add Campsite" : "Cancel"}
        </button>
        <button
          onClick={() => setShowLocationModal(true)}
          className="btn btn-warning btn-lg btn-block"
        >
          Add Location
        </button>
      </div>
    </React.Fragment>
  );
};

export default RoutePointListFooter;
