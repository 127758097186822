import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import TopicListItem from "./TopicListItem";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const TopicList = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Pagination state
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();
  //Topics data
  const [topics, setTopics] = useState();

  //Set the pagination page
  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Reload topics on page number change and when the global refresh state is toggled
  useEffect(() => {
    const getTopics = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/topics?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          authCtx.config
        );
        //Store the topics data
        setTopics(resp.data);
        //Get the pagination metadata from the header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        // Log Error
        console.log(error);
      }
    };
    getTopics();
  }, [authCtx.refresh, authCtx.config, pageNumber]);

  return (
    <React.Fragment>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <Row>
            <Col sm={8} className="font-weight-bold">
              Topic
            </Col>
            <Col sm={2} className="font-weight-bold">
              Posts
            </Col>
          </Row>
        </li>
        {topics &&
          topics.map((topic) => (
            <TopicListItem
              key={topic.id}
              topic={topic}
              setSelectedPage={pageNumberHandler}
            />
          ))}
      </ul>
      <div className="d-flex justify-content-center">
        {paginationData && (
          <PaginationDisplay
            pagination={paginationData}
            pageSelected={pageNumberHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TopicList;
