import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import CostItem from "./CostItem";

const CostsModal = (props) => {
  //Refs
  const cost = useRef();
  const title = useRef();

  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);

  //Validation error state
  const [validationErrors, setValidationErrors] = useState({});

  const [totalCost, setTotalCost] = useState("0.00");

  //Cost data
  const [costs, setCosts] = useState();
  //Global State
  const authCtx = useContext(AuthContext);
  //Use params to enable access to URL data
  const params = useParams();
  //Used to reset validation on cost item components
  const [clearItemsValidation, setClearItemsValidation] = useState(false);

  useEffect(() => {
    const getCosts = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/route/getCosts/${params.routeId}`,
          authCtx.config
        );
        setCosts(resp.data);
        calculateTotalCost(resp.data);
      } catch (error) {
        //Log Error
        console.log(error.response);
      }
    };
    getCosts();
  }, [authCtx.refresh, authCtx.config, params.routeId]);

  const calculateTotalCost = (data) => {
    var costAdd = 0;
    //Total all the costs and limit to 2 decimal places
    data.map((cost) => (costAdd += cost.amount));
    setTotalCost(costAdd.toFixed(2));
  };

  const addCost = async (data) => {
    //Show loading spinner
    setIsLoading(true);
    //Reset validation errors
    setValidationErrors({});
    try {
      await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/route/addCost",
        data,
        authCtx.config
      );
      //Clear the input fields
      Array.from(document.querySelectorAll("input")).map(
        (item) => (item.value = item.defaultValue)
      );
      //Reset cost item validation errors
      setClearItemsValidation(!clearItemsValidation);
      //Rerun API requests
      authCtx.refreshData();
      //Hide spinner
      setIsLoading(false);
    } catch (error) {
      //Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  const addCostHandler = () => {
    //Create data object
    const data = {
      title: title.current.value,
      amount: cost.current.value,
      routeId: params.routeId,
    };
    addCost(data);
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Costs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {costs && (
            <ul
              className="list-group list-group-flush mb-3 overflow-auto"
              style={{ height: "30vh" }}
            >
              {costs.map((cost) => (
                <CostItem
                  key={cost.id}
                  cost={cost}
                  clearValidation={clearItemsValidation}
                />
              ))}
            </ul>
          )}
          <InputGroup>
            <FormControl placeholder="Item" ref={title} />
            <span
              className="font-weight-bold pl-2 pr-1"
              style={{ fontSize: "1.3em" }}
            >
              £
            </span>
            <FormControl
              type="number"
              min="0.00"
              step=".01"
              defaultValue="0.00"
              ref={cost}
            />
            {isLoading && (
              <Spinner
                animation="border"
                variant="primary"
                className="float-right"
              />
            )}
            {!isLoading && (
              <Button
                variant="primary"
                onClick={() => {
                  addCostHandler();
                  setValidationErrors({});
                }}
              >
                Add
              </Button>
            )}
          </InputGroup>
          {validationErrors.Title && (
            <span className="text-danger">{validationErrors.Title[0]}</span>
          )}{" "}
          {validationErrors.Amount && (
            <span className="text-danger">{validationErrors.Amount[0]}</span>
          )}
          <p className="float-right pt-3">Total Costs: £{totalCost}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CostsModal;
