import React from "react";
import { Modal } from "react-bootstrap";
import NotificationItem from "./NotificationItem";

const NotificationsModal = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.invites && (
            <ul
              className="list-group list-group-flush mb-3 overflow-auto p-3"
              style={{ height: "20vh" }}
            >
              {props.invites.map((invite) => (
                <NotificationItem
                  noOfNotification={props.invites.length}
                  invite={invite}
                  key={invite.id}
                  handleClose={props.handleClose}
                />
              ))}
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationsModal;
