import React from "react";
import RouteListItem from "./RouteListItem";

const RouteList = (props) => {
  return (
    <React.Fragment>
      {props.routes && (
        <ul className="w-100 h-100 overflow-auto list-group list-group-flush">
          {props.routes.map((route) => (
            <RouteListItem key={route.id} route={route} />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default RouteList;
