import React from "react";
import { Button, Modal, Row } from "react-bootstrap";

const MarkerDetailsModal = (props) => {
  //Display all point data passed through as props
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            {props.point.properties.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <dt className="col-3">
              <p>Address</p>
            </dt>
            <dd className="col-9">
              <p>
                {props.point.properties.address_line1},{" "}
                {props.point.properties.address_line2}
              </p>
            </dd>

            <dt className="col-3">
              <p>Tags</p>
            </dt>
            <dd className="col-9">
              {props.point.properties.categories.map((tag) => tag + ", ")}
            </dd>

            <dt className="col-3">
              <p>Coordinates</p>
            </dt>
            <dd className="col-9">
              <p>
                {props.point.properties.lon}
                {", "}
                {props.point.properties.lat}
              </p>
            </dd>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-sm" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MarkerDetailsModal;
