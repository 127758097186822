import React from "react";
import UserListItem from "./UserListItem";

const UserListPaginate = (props) => {
  return (
    <React.Fragment>
      {props.users && (
        <ul className="w-100 list-group list-group-flush">
          {props.users.map((user) => (
            <UserListItem key={user.username} user={user} />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default UserListPaginate;
