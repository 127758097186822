import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Tab, Tabs, Alert } from "react-bootstrap";
import CampsiteTab from "../components/Manage/CampsiteTab";
import UserTab from "../components/Manage/UserTab";

const Manage = () => {
  //Active tab state
  const [activeKey, setActiveKey] = useState("campsites");

  return (
    <Container fluid className="pl-3 pr-3">
      <h3 className="h3 mt-3">Manage</h3>
      <Alert variant="danger">
        <p className="text-justify">
          Note: ANY CHANGES MADE ARE PERMANENT, DO NOT SHARE ADMIN ACCOUNT
          DETAILS
        </p>
      </Alert>
      <Tabs
        activeKey={activeKey}
        onSelect={(key) => setActiveKey(key)}
        className="mb-3 mt-2"
        fill
      >
        <Tab eventKey="campsites" title="Campsites" tabClassName="text-dark">
          <CampsiteTab />
        </Tab>
        <Tab eventKey="users" title="Users" tabClassName="text-dark">
          <UserTab />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Manage;
