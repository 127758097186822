import React, { useContext, useState } from "react";
import { Modal, InputGroup, FormControl, Button, Alert } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import RouteUserItem from "./RouteUserItem";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RouteUsersModal = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //State
  const [username, setUsername] = useState();
  //Access parameters in URL
  const params = useParams();

  const sendInvite = async (data) => {
    //Show spinner
    setIsLoading(true);
    try {
      const resp = await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/route/sendInvite",
        data,
        authCtx.config
      );
      //Reset the input to default value: ""
      Array.from(document.querySelectorAll("input")).map(
        (item) => (item.value = item.defaultValue)
      );
      //Clear the username state
      setUsername("");
      //Hide spinner
      setIsLoading(false);
      //Toast Notfication
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Handle Error
      error.response &&
        error.response.data &&
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      //Hide spinner
      setIsLoading(false);
    }
  };

  const addUserHandler = () => {
    //Create data object to store invite data
    const data = { username, routeId: params.routeId };
    sendInvite(data);
  };
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Route Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            All users in the trip! Only the route owner can invite and remove
            users.
          </Alert>
          <ul
            className="list-group list-group-flush mb-3 overflow-auto"
            style={{ height: "26vh" }}
          >
            <li className="list-group-item border-top border-danger">
              <span className="font-weight-bold">Route Admin: </span>{" "}
              {props.route.username}
            </li>
            {props.route.routeAccess.map((access) => (
              <RouteUserItem
                routeOwner={props.route.username}
                routeAccess={access}
                handleClose={props.handleClose}
              />
            ))}
          </ul>
          {props.route.username === authCtx.username && (
            <InputGroup>
              <FormControl
                placeholder="Username"
                onChange={(event) => setUsername(event.target.value)}
              />
              {!isLoading && (
                <Button variant="primary" onClick={addUserHandler}>
                  Send Invite
                </Button>
              )}
              {isLoading && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="float-right"
                />
              )}
            </InputGroup>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RouteUsersModal;
