import React, { useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import LoginForm from "./Login/LoginForm";
import RegisterForm from "./Register/RegisterForm";

const LoginRegisterModal = (props) => {
  //State to control the active tab
  const [activeKey, setActiveKey] = useState("login");
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Welcome Back!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
            className="mb-3"
            fill
          >
            <Tab eventKey="login" title="Login" tabClassName="text-dark">
              <div className="p-3">
                <LoginForm success={props.handleClose} />
              </div>
            </Tab>
            <Tab eventKey="register" title="Register" tabClassName="text-dark">
              <div className="p-3">
                <RegisterForm success={() => setActiveKey("login")} />
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginRegisterModal;
