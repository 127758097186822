import React, { useState, useContext, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import CampsiteListPaginate from "./CampsiteListPaginate";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const CampsiteTab = () => {
  //Pagination
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  //Campsite
  const [campsiteSearchInput, setCampsiteSearchInput] = useState("");
  const [campsites, setCampsites] = useState([]);

  //Global State
  const authCtx = useContext(AuthContext);

  //Set pagination page
  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Get campsites on page load, search input change or page number change
  useEffect(() => {
    const getCampsites = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/getcampsites/paginate?PageNumber=${pageNumber}&PageSize=${pageSize}&QuerySearch=${campsiteSearchInput}`,
          authCtx.config
        );
        //store campsite data in state
        setCampsites(resp.data);
        //Get the pagination metadata from the header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        //Log Error
        console.log(error.response);
      }
    };
    getCampsites();
  }, [campsiteSearchInput, pageNumber, authCtx.refresh, authCtx.config]);

  return (
    <React.Fragment>
      {campsites && (
        <InputGroup className="mb-3 w-25 ml-3">
          <FormControl
            onChange={(event) => {
              setCampsiteSearchInput(event.target.value);
              setPageNumber(1);
            }}
            placeholder="Campsite Name..."
            aria-label="Campsite name"
          />
        </InputGroup>
      )}

      {campsites && <CampsiteListPaginate campsites={campsites} />}
      <div className="d-flex justify-content-center">
        {paginationData && (
          <PaginationDisplay
            pagination={paginationData}
            pageSelected={pageNumberHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CampsiteTab;
