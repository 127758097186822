import React from "react";
import CampsiteDetails from "../ViewDetails/CampsiteDetails";
import { Modal, Button } from "react-bootstrap";

const CampsiteDetailsModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Campsite Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CampsiteDetails campsite={props.campsite} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-sm" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampsiteDetailsModal;
