import React, { useContext } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { RiSubtractFill } from "react-icons/ri";
import AuthContext from "../../../store/auth-context";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const RouteUserItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //History used to redirect to other pages
  const history = useHistory();

  const removeRouteAccess = async (data) => {
    try {
      const resp = await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/route/removeRouteAccess?RouteId=${props.routeAccess.routeId}&Username=${props.routeAccess.username}`,
        authCtx.config
      );
      //Close modal
      props.handleClose();
      //Toast Notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Reload API data
      authCtx.username != props.routeAccess.username && authCtx.refreshData();
      //Redirect to routes if the user leaves the route
      //Do not redirect if the route owner removes the user
      authCtx.username === props.routeAccess.username &&
        history.push("/routes");
    } catch (error) {
      //Handle Error
      error.response &&
        error.response.data &&
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      <li className="list-group-item border-top">
        <Row>
          <Col sm={2}>
            {authCtx.username === props.routeOwner && (
              <RiSubtractFill
                role="button"
                className="m-1"
                color="red"
                onClick={() => removeRouteAccess()}
              />
            )}
            {authCtx.username === props.routeAccess.username && (
              <Button
                variant="danger btn-sm"
                onClick={() => removeRouteAccess()}
              >
                Leave
              </Button>
            )}
          </Col>
          <Col sm={10}>
            <p>{props.routeAccess.username}</p>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default RouteUserItem;
