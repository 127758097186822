import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationDisplay = (props) => {
  //Stores the pagination items
  const [items, setItems] = useState([]);

  //Recalculate pagination when props change
  useEffect(() => {
    const calculatePagination = () => {
      let arry = [];
      //Create pagination number for the number of total pages and add to the array
      for (let number = 1; number <= props.pagination.TotalPages; number++) {
        arry.push(
          <Pagination.Item
            key={number}
            // Set the active page to the current page specified in the props
            active={number === props.pagination.CurrentPage}
            onClick={() => props.pageSelected(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      setItems(arry);
    };
    calculatePagination();
  }, [props]);

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
};

export default PaginationDisplay;
