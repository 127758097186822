import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, InputGroup, FormControl } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import UserListPaginate from "./UserListPaginate";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const UserTab = () => {
  //Global State
  const authCtx = useContext(AuthContext);

  //User State
  const [adminChecked, setAdminChecked] = useState(true);
  const [campsiteUserChecked, setCampsiteUserChecked] = useState(true);
  const [userChecked, setUserChecked] = useState(true);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [users, setUsers] = useState([]);

  //Pagination State
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Get the users when the search input changes, page number changes, checked values change or global refresh is toggled
  useEffect(() => {
    const getUsers = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/user/getUsers/paginate?PageNumber=${pageNumber}&PageSize=${pageSize}&QuerySearch=${userSearchInput}&Admin=${adminChecked}&CampsiteUsers=${campsiteUserChecked}&Users=${userChecked}`,
          authCtx.config
        );
        //Set user response data
        setUsers(resp.data);
        //Store pagination metadata in the response header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
        console.log(resp);
      } catch (error) {
        //Log Error
        console.log(error.response);
      }
    };
    getUsers();
  }, [
    userSearchInput,
    pageNumber,
    campsiteUserChecked,
    adminChecked,
    userChecked,
    authCtx.refresh,
    authCtx.config,
  ]);

  return (
    <React.Fragment>
      {users && (
        <div>
          <Row>
            <Col sm={9}>
              <span className="mr-4">
                <Form.Check
                  defaultChecked={true}
                  onClick={(event) => setAdminChecked(event.target.checked)}
                  inline
                  name="group1"
                  type="checkbox"
                />
                Admin
              </span>
              <span className="ml-4 mr-4">
                <Form.Check
                  defaultChecked={true}
                  onClick={(event) =>
                    setCampsiteUserChecked(event.target.checked)
                  }
                  inline
                  name="group1"
                  type="checkbox"
                />
                CampsiteUser
              </span>
              <span className="ml-4 mr-4">
                <Form.Check
                  defaultChecked={true}
                  onClick={(event) => setUserChecked(event.target.checked)}
                  inline
                  name="group1"
                  type="checkbox"
                />
                User
              </span>
            </Col>
            <Col sm={3}>
              <InputGroup className="mb-3">
                <FormControl
                  onChange={(event) => {
                    setUserSearchInput(event.target.value);
                    setPageNumber(1);
                  }}
                  placeholder="Search..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </InputGroup>
            </Col>
          </Row>
          <UserListPaginate users={users} />{" "}
          <div className="d-flex justify-content-center">
            {paginationData && (
              <PaginationDisplay
                pagination={paginationData}
                pageSelected={pageNumberHandler}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserTab;
