import React, { useState, useContext } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaCaravan } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { RiSubtractFill } from "react-icons/ri";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import ReactDOM from "react-dom";
import ViewEditLocationModal from "../Modal/Route/ViewEditLocationModal";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewEditCampsiteModal from "../Modal/Route/ViewEditCampsiteModal";

const RoutePointListItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Modal Visibility State
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showViewEditLocationModal, setShowViewEditLocationModal] =
    useState(false);
  const [showViewEditCampsiteModal, setShowViewEditCampsiteModal] =
    useState(false);

  const deleteRoutePoint = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/routepoint/delete/${props.routepoint.id}`,
        authCtx.config
      );
      //Reload API data
      authCtx.refreshData();
      //Toast Notification
      toast.success("Route Point Deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      error.response &&
        error.response.data &&
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      console.log(error.response);
    }
  };
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={deleteRoutePoint}
          header={"Delete Route Point"}
          message={
            props.routepoint.campsite
              ? `Are you sure you want to remove - ${props.routepoint.campsite.title}?`
              : props.routepoint.location &&
                `Are you sure you want to remove - ${props.routepoint.location.address}?`
          }
        />,
        document.getElementById("delete-confirm-modal")
      )}
      {showViewEditLocationModal &&
        ReactDOM.createPortal(
          <ViewEditLocationModal
            show={showViewEditLocationModal}
            handleClose={() => setShowViewEditLocationModal(false)}
            routepoint={props.routepoint}
          />,
          document.getElementById("view-edit-location-modal")
        )}
      {showViewEditCampsiteModal &&
        ReactDOM.createPortal(
          <ViewEditCampsiteModal
            show={showViewEditCampsiteModal}
            handleClose={() => setShowViewEditCampsiteModal(false)}
            routepoint={props.routepoint}
          />,
          document.getElementById("view-edit-campsite-modal")
        )}
      <li className="list-group-item">
        <div className="row">
          <div className="col-1">
            <RiSubtractFill
              role="button"
              className="m-1"
              color="red"
              onClick={() => {
                setShowDeleteConfirmModal(true);
              }}
            />
          </div>
          <div className="col-5 lead">
            {props.routepoint.campsite ? (
              <div>
                <FaCaravan className="text-primary" />{" "}
                {props.routepoint.campsite.title}
              </div>
            ) : (
              <div>
                <HiLocationMarker className="text-warning" />{" "}
                {props.routepoint.location.address}
              </div>
            )}
          </div>
          <div className="col-3 lead">
            {props.routepoint.arrivalDate.toString().slice(0, 10)}
          </div>
          <div className="col-3">
            <BsChevronRight
              role="button"
              className="m-1 float-right"
              color="black"
              onClick={() =>
                props.routepoint.location
                  ? setShowViewEditLocationModal(true)
                  : setShowViewEditCampsiteModal(true)
              }
            />
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default RoutePointListItem;
