import React from "react";

const ForumRules = () => {
  return (
    <div className="text-justify text-secondary">
      <h1>Forum Guide</h1>
      <p>
        Welcome to the Camper Planner community! You can reply to posts or
        create posts of your own, everyone is here to help. Make sure you post
        in the relevant discussion.
      </p>
      <p>
        All user activity is shown below, note that posts and messages can be
        removed but not edited.
      </p>
      <p>
        We hope you enjoy the forum but there are a few rules you must follow:
      </p>
      <ul>
        <li>No Swearing</li>
        <li>No Spamming/Self Advertising</li>
        <li>No Trolling</li>
        <li>Respect Other Users</li>
      </ul>
      <p>
        The forum is moderated but if there are any issues please contact an
        admin.
      </p>
    </div>
  );
};

export default ForumRules;
