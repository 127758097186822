import React, { Fragment, useRef, useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../../store/auth-context";

const LoginForm = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);

  //loading spinner
  const [isLoading, setIsLoading] = useState(false);

  //input data
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  //error state
  const [error, setError] = useState(null);

  //redirect using react router
  const history = useHistory();

  const login = async (data) => {
    //Show loading spinner
    setIsLoading(true);
    //reset error
    setError(null);
    try {
      const resp = await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/user/login",
        data
      );

      //pass token as parameter to login function in context state
      authCtx.login(resp.data.token);
      //pass role to setRole function
      authCtx.setRole(resp.data.role);
      //pass username to setUsername function
      authCtx.setUsername(resp.data.username);
      //call success function closes modal in parent component
      props.success();
      //Hide loading spinner
      setIsLoading(false);
      //redirect page depending on user role
      resp.data.role === 2 && history.replace("/manage");
      resp.data.role === 0 && history.replace("/routes");
      resp.data.role == 1 && history.replace("/campsites");
    } catch (error) {
      //set error
      error.response && setError(error.response.data.message);
      console.log(error.response);
      //Hide loading spinner
      setIsLoading(false);
    }
  };

  const submitFormHandler = (event) => {
    //prevent refresh of page on submit
    event.preventDefault();
    //get data
    const emailValue = emailInputRef.current.value;
    const passwordValue = passwordInputRef.current.value;
    //store data in object
    const data = { email: emailValue, password: passwordValue };

    login(data);
  };

  return (
    <Fragment>
      <Form onSubmit={submitFormHandler}>
        <Form.Group controlId="formLogin">
          <Form.Control
            type="email"
            placeholder="Email"
            ref={emailInputRef}
            required
          />
        </Form.Group>

        <Form.Group controlId="formBasicPasswordLogin">
          <Form.Control
            type="password"
            placeholder="Password"
            ref={passwordInputRef}
            required
          />
        </Form.Group>

        <Form.Label className="text-danger">{error}</Form.Label>
        {isLoading && (
          <Spinner
            animation="border"
            variant="primary"
            className="float-right"
          />
        )}
        {!isLoading && (
          <Button className="float-right" variant="primary" type="submit">
            Sign In
          </Button>
        )}
      </Form>
    </Fragment>
  );
};

export default LoginForm;
