import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Alert,
  InputGroup,
  FormControl,
  ListGroupItem,
  Card,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";

const CovidInfo = () => {
  //State to store covid API results
  const [covidData, setCovidData] = useState();
  //Store filtered covid results
  const [filteredData, setFilteredData] = useState();
  //Store the search input
  const [countrySearchInput, setCountrySearchInput] = useState();

  //Run API request on page load
  useEffect(() => {
    const getCovidData = async () => {
      try {
        const resp = await axios.get(
          `https://disease.sh/v3/covid-19/countries`
        );
        //Store covid data and on load set the data to the filtered results
        setCovidData(resp.data);
        setFilteredData(resp.data);
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    getCovidData();
  }, []);

  //When the search input changes filter the covid data
  useEffect(() => {
    const filterSearchData = () => {
      var arry = [];
      //Loop through the results and if the search input is included in the item, add it to the array
      covidData.map(
        (item) =>
          item.country
            .toLowerCase()
            .includes(countrySearchInput.toLowerCase()) && arry.push(item)
      );

      //If the search input is empty set the filtered data to the initial data response else set it to the filtered results
      countrySearchInput === ""
        ? setFilteredData(covidData)
        : setFilteredData(arry);
    };

    covidData && countrySearchInput && filterSearchData();
  }, [countrySearchInput, covidData]);

  //Filter the API data according to the search input

  return (
    <Container fluid className="pr-3 pl-3">
      <Row className="mt-2 mb-2">
        <Col sm={9}>
          <h1 className="h1">Latest Covid Statistics</h1>
        </Col>
        <Col sm={3}>
          <InputGroup className="mb-3">
            <FormControl
              onChange={(event) => {
                setCountrySearchInput(event.target.value);
              }}
              placeholder="Search Country..."
              aria-label="Country"
              aria-describedby="basic-addon2"
            />
          </InputGroup>
        </Col>
      </Row>
      <Alert variant="info">
        Make an informed decision before travelling! Remember to sanitize your
        hands and stay safe! All data is updated daily (cases/deaths/critical by
        day).
      </Alert>

      <Row style={{ height: "70vh" }}>
        {filteredData &&
          filteredData.map((item) => (
            <Col sm={3} key={item.country}>
              <Card className="mb-3">
                <Card.Img variant="top" src={item.countryInfo.flag} />
                <Card.Body>
                  <Card.Title>{item.country}</Card.Title>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>Cases: {item.todayCases}</ListGroupItem>
                  <ListGroupItem>Deaths: {item.todayDeaths}</ListGroupItem>
                  <ListGroupItem>Critical: {item.critical}</ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default CovidInfo;
