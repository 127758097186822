import React from "react";
import { Container } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container className="pt-3">
      <h1 className="h1">Page Not Found</h1>
      <p>The page URL was is invalid</p>
    </Container>
  );
};

export default NotFound;
