import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import "./Map.css";
import PolylineOverlay from "./PolylineOverlay";
import { FaCaravan } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import AddCampsiteModal from "../../Modal/Route/AddCampsiteModal";
import ReactDOM from "react-dom";
import axios from "axios";
import { Row, Col, Button, Form } from "react-bootstrap";
import LocationInputAuto from "../../UI/LocationInputAuto";
import { FaToilet, FaParking } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { HiInformationCircle } from "react-icons/hi";
import InfoIcon from "../../UI/InfoOverlay/InfoIcon";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import MarkerDetailsModal from "../../Modal/MarkerDetailsModal";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxWorker from "mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const Map = (props) => {
  //Refs
  const wcRef = useRef();
  const pointOfInterestRef = useRef();
  const parkingRef = useRef();
  const touristInfoRef = useRef();
  //Default viewport
  const [viewport, setViewport] = useState({
    latitude: 46.683822210448724,
    longitude: 2.6288363119963765,
    zoom: 2,
  });

  //Stores the campsite or selected point to be passed to the modal
  const [selectedCampsite, setSelectedCampsite] = useState();
  const [selectedPoint, setSelectedPoint] = useState();
  //Modal Visibility State
  const [showCampsiteModal, setShowCampsiteModal] = useState(false);
  const [showPointDetailsModal, setShowPointDetailsModal] = useState(false);

  //State
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [refilterResultsToggle, setRefilterResultsToggle] = useState(false);
  //Stores API location results
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  //Stores the API results after they have been filtered
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);

  const locationHandler = (placeId) => {
    //Get the longitude and latitude of the placeId
    geocodeByPlaceId(placeId)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLongitude(lng);
        setLatitude(lat);
      })
      .catch((error) => console.log("error", error));
  };

  //If the API request is re-run the API data must be filtered again
  useEffect(() => {
    function filterSearchResults() {
      //Get current ref values
      const wcValue = wcRef.current.checked;
      const pointOfInterestValue = pointOfInterestRef.current.checked;
      const parkingValue = parkingRef.current.checked;
      const touristInfoValue = touristInfoRef.current.checked;

      //Filter data according to the checkbox state
      const newArry = [];
      //Loop through each result
      locationSearchResults.forEach((item) => {
        //Store the category
        const category = item.properties.categories;
        //Add the item to the array only if the checkbox is ticked
        category.includes("parking") && parkingValue && newArry.push(item);
        category.includes("amenity.toilet") && wcValue && newArry.push(item);
        category.includes("tourism.attraction") &&
          pointOfInterestValue &&
          newArry.push(item);
        category.includes("tourism.information") &&
          touristInfoValue &&
          newArry.push(item);
      });
      //Set the filtered results
      setFilteredSearchResults(newArry);
    }
    filterSearchResults();
  }, [locationSearchResults, refilterResultsToggle]);

  const campsiteClick = (campsite) => {
    //When a campsite marker is clicked set the selected campsite and show the campsite modal
    setSelectedCampsite(campsite);
    setShowCampsiteModal(true);
  };

  const pointInfoClickHandler = (point) => {
    //Set the selected point and show the point details modal
    setSelectedPoint(point);
    setShowPointDetailsModal(true);
  };

  const resultMarkers = useMemo(
    () =>
      //Loop through all filtered search results and set marker details
      filteredSearchResults.map((result) => (
        <React.Fragment>
          <Marker
            key={parseInt(result.properties.place_id)}
            longitude={result.properties.lon}
            latitude={result.properties.lat}
            offsetTop={-31}
            offsetLeft={-15}
            className={result.properties.place_id.toString()}
            //Add click event to the marker
            onClick={() => pointInfoClickHandler(result)}
          >
            {/* Set the icon depeding on the category */}
            {result.properties.categories.includes("parking") && (
              <FaParking color="blue" className="Marker" />
            )}
            {result.properties.categories.includes("amenity.toilet") && (
              <FaToilet color="#660033" className="Marker" />
            )}
            {result.properties.categories.includes("tourism.attraction") && (
              <AiFillEye color="darkgreen" className="Marker" />
            )}
            {result.properties.categories.includes("tourism.information") && (
              <HiInformationCircle color="red" className="Marker" />
            )}
          </Marker>
        </React.Fragment>
      )),
    [filteredSearchResults]
  );

  //Loop through the campsites and set marker details
  const campsiteMarkers = useMemo(
    () =>
      props.campsites.map((campsite) => (
        <Marker
          key={campsite.id}
          longitude={campsite.longitude}
          latitude={campsite.latitude}
          offsetTop={-31}
          offsetLeft={-15}
          className={campsite.id.toString()}
          //Add click event to the marker
          onClick={() => campsiteClick(campsite)}
        >
          <FaCaravan color="green" className="Marker" />
        </Marker>
      )),
    [props.campsites]
  );

  const locationGeoapifySearchHandler = useCallback(
    (data) => {
      //If a marker is clicked reset the viewport
      setViewport({
        latitude: data.latitude,
        longitude: data.longitude,
        zoom: 9,
      });
      //Rerun the API call to get data on the location
      const getLocationInfo = async (coordinate) => {
        try {
          const resp = await axios.get(
            `https://api.geoapify.com/v2/places?categories=amenity.toilet,tourism.attraction,tourism.information,parking&conditions=access,named&filter=circle:${coordinate.longitude},${coordinate.latitude},50000&bias=proximity:${coordinate.longitude},${coordinate.latitude}&limit=100&apiKey=${process.env.REACT_APP_GEOAPIFY_SECRET}`
          );
          setLocationSearchResults(resp.data.features);
        } catch (error) {
          // Handle Error
          console.log(error.response);
        }
      };

      getLocationInfo({ longitude: data.longitude, latitude: data.latitude });
    },
    [longitude, latitude]
  );

  //Loop through all routepoints and set marker details
  //Show different marker icons for campsite and location routepoints
  const routeMarkers = useMemo(
    () =>
      props.routePoints.map((routepoint) =>
        routepoint.location ? (
          <Marker
            onClick={() => locationGeoapifySearchHandler(routepoint.location)}
            key={routepoint.id}
            longitude={routepoint.location.longitude}
            latitude={routepoint.location.latitude}
            offsetTop={-31}
            offsetLeft={-15}
          >
            <HiLocationMarker className="text-warning Marker" />
          </Marker>
        ) : (
          <Marker
            onClick={() => locationGeoapifySearchHandler(routepoint.campsite)}
            key={routepoint.id}
            longitude={routepoint.campsite.longitude}
            latitude={routepoint.campsite.latitude}
            offsetTop={-31}
            offsetLeft={-15}
          >
            <FaCaravan className="text-primary Marker" />
          </Marker>
        )
      ),
    [props.routePoints, locationGeoapifySearchHandler]
  );

  return (
    <React.Fragment>
      {selectedCampsite &&
        ReactDOM.createPortal(
          <AddCampsiteModal
            show={showCampsiteModal}
            handleClose={() => setShowCampsiteModal(false)}
            campsite={selectedCampsite}
            toggleCancelButton={props.toggleCancelButton}
          />,
          document.getElementById("add-campsite-modal")
        )}
      {selectedPoint &&
        ReactDOM.createPortal(
          <MarkerDetailsModal
            show={showPointDetailsModal}
            handleClose={() => setShowPointDetailsModal(false)}
            point={selectedPoint}
            toggleCancelButton={props.toggleCancelButton}
          />,
          document.getElementById("marker-details-modal")
        )}
      <Row className="m-1">
        <Col className="col-8">
          <Row>
            <Col className="col-1">
              <InfoIcon message="Search for Points of Interest by location - You can also click on your route points to show close-by activities! " />
            </Col>
            <Col className="col-8">
              <LocationInputAuto setData={locationHandler} />
            </Col>
            <Col className="col-3">
              <Button
                className="btn-primary"
                onClick={() =>
                  locationGeoapifySearchHandler({
                    latitude: latitude,
                    longitude: longitude,
                    zoom: 9,
                  })
                }
                disabled={longitude == null && latitude == null}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="col-4">
          <Form>
            <Row>
              <Col className="m-1">
                <Form.Check
                  defaultChecked={true}
                  onClick={() =>
                    setRefilterResultsToggle(!refilterResultsToggle)
                  }
                  ref={wcRef}
                  inline
                  name="group1"
                  type="checkbox"
                />{" "}
                <FaToilet color="#660033" />
              </Col>
              <Col className="m-1">
                <Form.Check
                  defaultChecked={true}
                  onClick={() =>
                    setRefilterResultsToggle(!refilterResultsToggle)
                  }
                  ref={pointOfInterestRef}
                  inline
                  name="group2"
                  type="checkbox"
                />{" "}
                <AiFillEye color="darkgreen" />
              </Col>
              <Col className="m-1">
                <Form.Check
                  defaultChecked={true}
                  onClick={() =>
                    setRefilterResultsToggle(!refilterResultsToggle)
                  }
                  ref={parkingRef}
                  inline
                  name="group4"
                  type="checkbox"
                />
                <FaParking color="blue" />
              </Col>
              <Col className="m-1">
                <Form.Check
                  defaultChecked={true}
                  onClick={() =>
                    setRefilterResultsToggle(!refilterResultsToggle)
                  }
                  ref={touristInfoRef}
                  inline
                  name="group5"
                  type="checkbox"
                />
                <HiInformationCircle color="red" />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
        width="101%"
        height="96%"
        onViewportChange={setViewport}
      >
        {/* Show he polyline overlay */}
        <PolylineOverlay points={props.points} />
        {/* Show routepoint markers */}
        {props.routePoints && routeMarkers}
        {/* Show campsite markers when the add campsite button is clicked */}
        {props.showMarker && campsiteMarkers}
        {/* Show location info markers once the data has been filtered */}
        {locationSearchResults && filteredSearchResults && resultMarkers}
      </ReactMapGL>
    </React.Fragment>
  );
};

export default Map;
