import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ForumPostItem = (props) => {
  return (
    <React.Fragment>
      <li className="list-group-item">
        <Row>
          <Col sm={4}>{props.post.title}</Col>
          <Col sm={2}>{props.post.username}</Col>
          <Col sm={3}>{props.post.createdOn.toString().slice(0, 10)}</Col>
          <Col sm={1}>{props.post.replies.length}</Col>
          <Col sm={1}>{props.post.upvotes.length}</Col>
          <Col sm={1}>
            <Link
              to={`/forum/post/${props.post.id}`}
              className="text-decoration-none"
            >
              <BsChevronRight className="m-1 float-right" color="black" />
            </Link>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default ForumPostItem;
