import React from "react";
import { Card, Button } from "react-bootstrap";
import { IoMdArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";

const ForumNavigateCard = () => {
  return (
    <Card border="warning">
      <Card.Body>
        <Card.Title>Need some road trip inspiration?</Card.Title>
        <Card.Text>
          Our forum is dedicated to motorhome travel, get some road trip ideas
          or discover some amazing places to visit!
        </Card.Text>
        <Link to="/forum/">
          <Button className="btn-sm float-right btn-warning text-dark">
            Check it out
            <IoMdArrowDropright />
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default ForumNavigateCard;
