import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { RiSubtractFill } from "react-icons/ri";
import ReactDOM from "react-dom";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const UserReplyItem = (props) => {
  //Global state
  const authCtx = useContext(AuthContext);

  //Toggle modal visibility
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const deleteReply = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/reply/delete/${props.reply.id}`,
        authCtx.config
      );
      //Update the selected pagination page
      props.setSelectedPage(1);
      //Rerun API requests
      authCtx.refreshData();
      //Toast Notification
      toast.success("Reply Deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Log Error
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={deleteReply}
          header={"Delete Reply"}
          message={
            props.reply &&
            `Are you sure you want to delete the message on post - ${props.reply.post.title}?`
          }
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li className="list-group-item">
        <Row>
          <Col sm={1}>
            <RiSubtractFill
              role="button"
              className="m-1"
              color="red"
              onClick={() => {
                setShowDeleteConfirmModal(true);
              }}
            />
          </Col>
          <Col sm={8}>
            <div className="text-break">{props.reply.post.title}</div>
          </Col>
          <Col sm={2}>{props.reply.createdOn.toString().slice(0, 10)}</Col>
          <Col sm={1}>
            <Link
              to={`/forum/post/${props.reply.postId}`}
              className="text-decoration-none m-1 float right"
            >
              <BsChevronRight className="m-1 float-right" color="black" />
            </Link>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default UserReplyItem;
