import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import LocationInputAuto from "../../UI/LocationInputAuto";
import { Form, Button, Col, Row } from "react-bootstrap";
import CampsiteLocationMap from "../CampsiteMap/CampsiteLocationMap";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import axios from "axios";
import { AiOutlinePlus } from "react-icons/ai";
import { RiSubtractFill } from "react-icons/ri";
import ImageGallery from "react-image-gallery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoIcon from "../../UI/InfoOverlay/InfoIcon";
import Spinner from "react-bootstrap/Spinner";

const AddCampsiteForm = () => {
  //global state
  const authCtx = useContext(AuthContext);

  //loading spinner
  const [isLoading, setIsLoading] = useState(false);

  //Validation
  const [validationErrors, setValidationErrors] = useState({});
  const [imageValidationErrors, setImageValidationErrors] = useState();

  //redirect using react router
  const history = useHistory();

  //Location state
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [address, setAddress] = useState("");

  //Refs
  const titleRef = useRef();
  const descriptionRef = useRef();
  const wcRef = useRef();
  const dogFriendlyRef = useRef();
  const electricRef = useRef();
  const wifiRef = useRef();
  const chemicalDisposalRef = useRef();
  const waterPointRef = useRef();
  const webAddressRef = useRef();
  const telNoRef = useRef();
  const emailRef = useRef();
  const highSeasonRef = useRef();
  const lowSeasonRef = useRef();

  //image state
  const [changedFile, setChangedFile] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imageGalleryList, setImageGalleryList] = useState(null);

  const locationHandler = (placeId) => {
    //get the longitude and latitude from the place id using google geocoding
    geocodeByPlaceId(placeId)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLongitude(lng);
        setLatitude(lat);
      });

    //get the address from the placeId
    geocodeByPlaceId(placeId)
      .then((results) => setAddress(results[0].formatted_address))
      .then((error) => console.log(error));
  };

  const addCampsite = async (data) => {
    //Show spinner
    setIsLoading(true);
    //reset error validation
    setImageValidationErrors();
    setValidationErrors({});
    //config to allow multipart form data so that images can be added to the request
    const config = {
      headers: {
        Authorization: `Bearer ${authCtx.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const resp = await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/campsite/add",
        data,
        config
      );
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Hide spinner
      setIsLoading(false);
      history.replace("/campsites");
    } catch (error) {
      //set error message
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      //set image error message
      error.response.data &&
        error.response.data.message &&
        setImageValidationErrors(error.response.data.message);
      //log error message to the console
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  const submitHandler = (event) => {
    //Prevent page refresh on submit
    event.preventDefault();
    //Get all form data
    const titleValue = titleRef.current.value;
    const descriptionValue = descriptionRef.current.value;
    const wcValue = wcRef.current.checked;
    const dogFriendlyValue = dogFriendlyRef.current.checked;
    const electricValue = electricRef.current.checked;
    const wifiValue = wifiRef.current.checked;
    const chemicalDisposalValue = chemicalDisposalRef.current.checked;
    const waterPointValue = waterPointRef.current.checked;
    const webAddressValue = webAddressRef.current.value;
    const telNoValue = telNoRef.current.value;
    const emailValue = emailRef.current.value;
    const highSeasonValue = highSeasonRef.current.value;
    const lowSeasonValue = lowSeasonRef.current.value;

    //Add all values to form data object
    var formData = new FormData();
    formData.append("Title", titleValue);
    formData.append("Description", descriptionValue);
    formData.append("Longitude", longitude);
    formData.append("Latitude", latitude);
    formData.append("Address", address);
    formData.append("HighSeasonCost", highSeasonValue);
    formData.append("LowSeasonCost", lowSeasonValue);
    formData.append("WC", wcValue);
    formData.append("DogFriendly", dogFriendlyValue);
    formData.append("Electric", electricValue);
    formData.append("Wifi", wifiValue);
    formData.append("ChemicalDisposal", chemicalDisposalValue);
    formData.append("DrinkingWaterPoint", waterPointValue);
    formData.append("ContactEmail", emailValue);
    formData.append("ContactTelNo", telNoValue);
    formData.append("WebAddress", webAddressValue);
    //Add all images to form data
    imageList.forEach((image) => {
      formData.append("CampsiteImage", image);
    });
    addCampsite(formData);
  };

  const addImageHandler = () => {
    //Ensure the filename is unique and if it is add to the array
    if (imageList.filter((i) => i.name == changedFile.name).length == 0) {
      setImageList((prev) => [...prev, changedFile]);
    }
  };

  const fileChangedHandler = (event) => {
    //Store the data of the file
    setChangedFile(event.target.files[0]);
  };

  useEffect(() => {
    //If the image list state changes run the createImageGalleryList method
    createImageGalleryList();
    // eslint-disable-next-line
  }, [imageList]);

  const createImageGalleryList = useCallback(() => {
    const arry = [];
    //Add all images to the array in format accepted by the slideshow
    imageList.forEach((image) => {
      arry.push({ original: URL.createObjectURL(image) });
    });
    setImageGalleryList(arry);
  }, [imageList]);

  const removeImageHandler = (image) => {
    //Remove the image from the list
    setImageList((prev) => prev.filter((i) => i != image));
  };

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control ref={titleRef} placeholder="Campsite name" />
          {validationErrors.Title && (
            <span className="text-danger">{validationErrors.Title[0]}</span>
          )}
        </Form.Group>

        <Form.Group controlId="address">
          <Form.Label>
            Search for Address (Powered by google){"  "}
            {latitude && `(Longitude: ${longitude}  Latitude: ${latitude})`}
          </Form.Label>
          <LocationInputAuto setData={locationHandler} />
          {validationErrors.Address && (
            <span className="text-danger">{validationErrors.Address[0]}</span>
          )}
        </Form.Group>

        <Row>
          <Col sm={8}>
            <Form.Group controlId="description">
              <Form.Label>Brief Description</Form.Label>
              <Form.Control ref={descriptionRef} as="textarea" rows={8} />
              {validationErrors.Description && (
                <span className="text-danger">
                  {validationErrors.Description[0]}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col sm={4}>
            {longitude && latitude && (
              <CampsiteLocationMap longitude={longitude} latitude={latitude} />
            )}
          </Col>
        </Row>

        <Form.Label className="mb-3">Facilities</Form.Label>

        <Form.Row>
          <Col>
            <Form.Check ref={wcRef} type="checkbox" label="WC" />
          </Col>
          <Col>
            <Form.Check
              ref={dogFriendlyRef}
              type="checkbox"
              label="Dog Friendly"
            />
          </Col>
          <Col>
            <Form.Check ref={electricRef} type="checkbox" label="Electric" />
          </Col>
          <Col>
            <Form.Check ref={wifiRef} type="checkbox" label="Wifi" />
          </Col>
          <Col>
            <Form.Check
              ref={chemicalDisposalRef}
              type="checkbox"
              label="Chemical disposal"
            />
          </Col>
          <Col>
            <Form.Check
              ref={waterPointRef}
              type="checkbox"
              label="Drinking Water Point"
            />
          </Col>
        </Form.Row>

        <Row className="mt-3">
          <Col sm={6}>
            <Form.Group>
              <Form.Label>High Season Average Cost per Day(£)</Form.Label>
              <Form.Control
                ref={highSeasonRef}
                placeholder="Cost in £"
                type="number"
                min="0.00"
                step=".01"
                defaultValue="0.00"
              />
              {validationErrors.HighSeasonCost && (
                <span className="text-danger">
                  {validationErrors.HighSeasonCost[0]}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Low Season Average Cost per Day(£)</Form.Label>
              <Form.Control
                ref={lowSeasonRef}
                placeholder="Cost in £"
                type="number"
                min="0.00"
                step=".01"
                defaultValue="0.00"
              />{" "}
              {validationErrors.LowSeasonCost && (
                <span className="text-danger">
                  {validationErrors.LowSeasonCost[0]}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={6}>
            <Form.Group controlId="webaddress">
              <Form.Label>Web Address</Form.Label>
              <Form.Control
                ref={webAddressRef}
                placeholder="Enter Web Address"
                type="url"
              />
              {validationErrors.WebAddress && (
                <span className="text-danger">
                  {validationErrors.WebAddress[0]}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="webaddress">
              <Form.Label>Contact Telephone No</Form.Label>
              <Form.Control
                ref={telNoRef}
                placeholder="Enter Tel No"
                type="tel"
              />
              {validationErrors.ContactTelNo && (
                <span className="text-danger">
                  {validationErrors.ContactTelNo[0]}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col sm="12">
            <Form.Group controlId="webaddress">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                placeholder="Enter Email"
                type="email"
              />
              {validationErrors.ContactEmail && (
                <span className="text-danger">
                  {validationErrors.ContactEmail[0]}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={2}>
            <Form.Group>
              <Form.Label>Add Campsite Image</Form.Label>
              <Form.File
                accept="image/*"
                onChange={(event) => fileChangedHandler(event)}
              />
            </Form.Group>
          </Col>
          <Col sm={1}>
            <InfoIcon message="Choose an image and add it using the plus button. You can remove an image using the minus button in the image list. All campsites must have at least one image added." />
          </Col>

          <Col sm={1}>
            <Button
              variant="primary btn-sm mt-3"
              onClick={() => addImageHandler()}
            >
              <AiOutlinePlus />
            </Button>
          </Col>
          <Col sm={3} className="mt-3">
            {imageGalleryList && (
              <ImageGallery
                items={imageGalleryList}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
              />
            )}
          </Col>
          <Col sm={5}>
            <ul
              class="list-group list-group-flush overflow-auto"
              style={{ height: "20vh" }}
            >
              {imageList &&
                imageList.map((image) => (
                  <li key={image.name} class="list-group-item">
                    <Row>
                      <Col sm={1}>
                        <RiSubtractFill
                          role="button"
                          color="red"
                          onClick={() => removeImageHandler(image)}
                        />
                      </Col>
                      <Col>{image.name}</Col>
                    </Row>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        {validationErrors.CampsiteImage && (
          <span className="text-danger">
            {validationErrors.CampsiteImage[0]}
          </span>
        )}
        {imageValidationErrors && (
          <span className="text-danger ml-3">{imageValidationErrors}</span>
        )}

        {isLoading && (
          <Spinner
            animation="border"
            variant="primary"
            className="float-right"
          />
        )}
        {!isLoading && (
          <Button className="float-right" variant="primary" type="submit">
            Add
          </Button>
        )}
      </Form>
    </React.Fragment>
  );
};

export default AddCampsiteForm;
