import React, { useContext, useRef, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import AuthContext from "../../../store/auth-context";

const EditTopicModal = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //Validation State
  const [validationErrors, setValidationErrors] = useState({});
  //Refs
  const titleRef = useRef();

  const submitFormHandler = (event) => {
    //Prevent refresh
    event.preventDefault();
    const data = { title: titleRef.current.value };
    updateTopic(data);
  };

  const updateTopic = async (data) => {
    //Show spinner
    setIsLoading(true);
    //Reset validation errors
    setValidationErrors({});
    try {
      const resp = await axios.put(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/topic/update/${props.topic.id}`,
        data,
        authCtx.config
      );
      //Refresh API data
      authCtx.refreshData();
      //Hide spinner
      setIsLoading(false);
      //close the modal
      props.handleClose();
      //Toast notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Handle error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Edit Topic</Modal.Title>
        </Modal.Header>{" "}
        <Form onSubmit={submitFormHandler}>
          <Modal.Body>
            <Form.Group controlId="formTitle">
              <Form.Control
                type="title"
                placeholder="Title"
                defaultValue={props.topic.title}
                ref={titleRef}
              />
              {validationErrors.Title && (
                <span className="text-danger">{validationErrors.Title[0]}</span>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {!isLoading && (
              <Button
                variant="secondary btn-sm"
                onClick={() => {
                  props.handleClose();
                  setValidationErrors({});
                }}
              >
                Close
              </Button>
            )}
            {!isLoading && (
              <Button variant="primary btn-sm" type="submit">
                Confirm
              </Button>
            )}
            {isLoading && (
              <Spinner
                animation="border"
                variant="primary"
                className="float-right"
              />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditTopicModal;
