import React, { useRef, useState, useContext, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import LocationInputAuto from "../../UI/LocationInputAuto";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../../store/auth-context";
import axios from "axios";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LocationModal = (props) => {
  //Global state
  const authCtx = useContext(AuthContext);
  //Used to access parameters in URL
  const params = useParams();
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //Validation state
  const [validationErrors, setValidationErrors] = useState({});
  //Refs
  const notesRef = useRef();
  //State
  const [date, setDate] = useState(new Date());
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [address, setAddress] = useState("");

  //Reset Stored state Data when modal closed
  useEffect(() => {
    setDate(new Date());
    setValidationErrors({});
    setLongitude(null);
    setLatitude(null);
    setAddress("");
  }, [props.handleClose]);

  const addLocation = async (data) => {
    //Show spinner
    setIsLoading(true);
    //Reset validation errors
    setValidationErrors({});
    try {
      await axios.post(
        `https://api-roadtrip-resource.azure-api.net/v1/api/routepoint/add/location`,
        data,
        authCtx.config
      );
      //Refresh API data
      authCtx.refreshData();
      toast.success("Location Added!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Hide spinner
      setIsLoading(false);
      //Close modal
      props.handleClose();
    } catch (error) {
      //Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  const locationHandler = (placeId) => {
    //Get the longitude and latitude from the placeId
    geocodeByPlaceId(placeId)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLongitude(lng);
        setLatitude(lat);
      });

    //Get the address from the placeId
    geocodeByPlaceId(placeId)
      .then((results) => setAddress(results[0].formatted_address))
      .then((error) => console.log(error));
  };

  const formSubmit = (event) => {
    //Prevent refresh
    event.preventDefault();
    const notesValue = notesRef.current.value;
    //Object including all data
    const data = {
      arrivalDate: date.toISOString(),
      address: address,
      notes: notesValue,
      longitude: longitude,
      latitude: latitude,
      routeId: params.routeId,
    };
    addLocation(data);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Add Location</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formSubmit}>
          <Modal.Body>
            <Form.Group controlId="formLocation">
              <Form.Label>Location</Form.Label>
              <LocationInputAuto setData={locationHandler} />
              {validationErrors.AddressNotSelected && (
                <span className="text-danger">
                  {validationErrors.AddressNotSelected}
                </span>
              )}
              {validationErrors.Address && (
                <span className="text-danger">
                  {validationErrors.Address[0]}
                </span>
              )}
              {(validationErrors.longitude || validationErrors.latitude) && (
                <span className="text-danger">Select an Address</span>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Arrival Date</Form.Label>
              <Form.Control
                type="datetime-local"
                onBlur={(event) => setDate(new Date(event.target.value))}
                required
              />
              {validationErrors.ArrivalDate && (
                <span className="text-danger">
                  {validationErrors.ArrivalDate[0]}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Notes</Form.Label>
              <Form.Control as="textarea" rows={5} ref={notesRef} />
              {validationErrors.Notes && (
                <span className="text-danger">{validationErrors.Notes[0]}</span>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {!isLoading && (
              <Button
                variant="secondary btn-sm"
                onClick={() => props.handleClose()}
              >
                Close
              </Button>
            )}
            {!isLoading && (
              <Button variant="primary btn-sm" type="submit">
                Add
              </Button>
            )}
            {isLoading && (
              <Spinner
                animation="border"
                variant="primary"
                className="float-right"
              />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default LocationModal;
