import React, { useContext, useRef, useState } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";

const AddReplyModal = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //Allows access to the URL params
  const params = useParams();
  //Validation state
  const [validationErrors, setValidationErrors] = useState({});
  //Refs
  const messageRef = useRef();

  const addMessageHandler = (event) => {
    //Prevent refresh
    event.preventDefault();
    //Create data object and store post data
    const data = { message: messageRef.current.value, postId: params.postId };
    addMessage(data);
  };

  const addMessage = async (data) => {
    //Show spinner
    setIsLoading(true);
    //Reset validation errors
    setValidationErrors({});
    try {
      const resp = await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/forum/reply/add",
        data,
        authCtx.config
      );
      //Toast notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Hide spinner
      setIsLoading(false);
      //Close the modal
      props.handleClose();
      //Refresh API data
      authCtx.refreshData();
    } catch (error) {
      // Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
          setValidationErrors({});
        }}
        keyboard={false}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Add Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Alert variant="info">
              <span className="text-danger">Reply to: </span>
              <div
                className="text-left text-break mt-3 text-dark"
                style={{ "white-space": "pre-line" }}
              >
                {props.postMessage.length > 120
                  ? props.postMessage.substring(0, 120) + "..."
                  : props.postMessage}
              </div>
            </Alert>
            <Form.Group>
              <Form.Control ref={messageRef} as="textarea" rows={4} />
              {validationErrors.Message && (
                <span className="text-danger">
                  {validationErrors.Message[0]}
                </span>
              )}
            </Form.Group>
            <Row className="mb-3">
              <Col sm={10} className="font-weight-bold">
                Ensure the reply fulfils the community guidelines
              </Col>
              <Col sm={2}>
                {!isLoading && (
                  <Button
                    onClick={addMessageHandler}
                    variant="primary btn-sm float-right"
                  >
                    Add
                  </Button>
                )}
                {isLoading && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="float-right"
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddReplyModal;
