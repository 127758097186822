import React, { useState, useEffect, useContext } from "react";
import ImageGallery from "react-image-gallery";
import { Form, Button, Col, Row } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { RiSubtractFill } from "react-icons/ri";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import { useParams } from "react-router";

const UpdateCampsiteImages = () => {
  //Global state
  const authCtx = useContext(AuthContext);
  const params = useParams();
  //Image state
  const [changedFile, setChangedFile] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imageGalleryList, setImageGalleryList] = useState(null);
  //Validation
  const [deleteValidationErrors, setDeleteValidationErrors] = useState();
  const [uploadValidationErrors, setUploadValidationErrors] = useState();

  const [reloadImages, setReloadImages] = useState(false);

  const addImage = async (data) => {
    //Reset error validation state
    setUploadValidationErrors();
    setDeleteValidationErrors();
    //config to allow multipart form data so that images can be added to the request
    const config = {
      headers: {
        Authorization: `Bearer ${authCtx.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/campsite/image/add",
        data,
        config
      );
      //Reload all campsite images
      setReloadImages(!reloadImages);
    } catch (error) {
      //Set error validation state
      error.response.data &&
        error.response.data.message &&
        setUploadValidationErrors(error.response.data.message);
      console.log(error.response);
    }
  };

  const removeImage = async (id) => {
    //Reset error validation state
    setDeleteValidationErrors();
    setUploadValidationErrors();
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/photo/${id}`,
        authCtx.config
      );
      //Reload all campsite images
      setReloadImages(!reloadImages);
    } catch (error) {
      //Set validation errors
      error.response.data &&
        error.response.data.message &&
        setDeleteValidationErrors(error.response.data.message);
      console.log(error.response);
    }
  };

  useEffect(() => {
    //Fetch images on page load
    const getCampsiteImages = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/photos/${params.campsiteId}`,
          authCtx.config
        );
        setImageList(resp.data);
      } catch (error) {
        //Log error message
        console.log(error.response);
      }
    };
    getCampsiteImages();
  }, [authCtx.config, params.campsiteId, reloadImages]);

  useEffect(() => {
    //If image list is not empty create image gallery list for the slide show
    //Recreate every time the image list state changes
    const createImageGalleryList = () => {
      const arry = [];
      //Add all images to the array in format accepted by the slideshow
      imageList.forEach((image) => {
        arry.push({ original: image.photoUrl });
      });
      setImageGalleryList(arry);
    };
    imageList && createImageGalleryList();
  }, [imageList]);

  const addImageHandler = () => {
    //Add values to form data object
    var formData = new FormData();
    formData.append("Image", changedFile);
    formData.append("CampsiteId", params.campsiteId);
    addImage(formData);
  };

  const fileChangedHandler = (event) => {
    //Store the data of the file
    setChangedFile(event.target.files[0]);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={4}>
          <Form.Group>
            <Form.File
              label="Add Campsite Image"
              accept="image/*"
              onChange={(event) => fileChangedHandler(event)}
            />
          </Form.Group>
        </Col>
        <Col sm={1}>
          <Button
            variant="primary btn-sm mt-3"
            onClick={() => addImageHandler()}
          >
            <AiOutlinePlus />
          </Button>
        </Col>
        <Col sm={7}>
          <ul
            class="list-group list-group-flush overflow-auto"
            style={{ height: "14vh" }}
          >
            {imageList &&
              imageList.map((image) => (
                <li key={image.id} class="list-group-item">
                  <Row>
                    <Col sm={1}>
                      <RiSubtractFill
                        role="button"
                        color="red"
                        onClick={() => removeImage(image.id)}
                      />
                    </Col>
                    <Col>{image.photoFileName}</Col>
                  </Row>
                </li>
              ))}
          </ul>
        </Col>
        <div className="m-3">
          {deleteValidationErrors && (
            <span className="text-danger">{deleteValidationErrors}</span>
          )}
          {uploadValidationErrors && (
            <span className="text-danger ml-3">{uploadValidationErrors}</span>
          )}
        </div>

        <Col sm={12} className="mt-3">
          {imageGalleryList && (
            <ImageGallery
              items={imageGalleryList}
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdateCampsiteImages;
