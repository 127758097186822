import React, { useState, useContext } from "react";
import { BsTrashFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import ReactDOM from "react-dom";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import CampsiteDetailsModal from "../Modal/CampsiteDetailsModal";
import { CgDetailsMore } from "react-icons/cg";

toast.configure();

const CampsiteListItem = (props) => {
  //Global state
  const authCtx = useContext(AuthContext);

  //Modal toggle state
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showCampsiteDetails, setShowCampsiteDetails] = useState(false);

  const deleteCampsite = async (data) => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/delete/${data}`,
        authCtx.config
      );
      //Rerun all API requests
      authCtx.refreshData();
      //Toast success
      toast.success("Campsite has been deleted", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      // Log error
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirm}
          handleClose={() => setShowDeleteConfirm(false)}
          header={"Delete Campsite"}
          message={
            props.campsite &&
            `Are you sure you want to delete campsite - ${props.campsite.title}?`
          }
          delete={() => deleteCampsite(props.campsite.id)}
        />,
        document.getElementById("delete-confirm-modal")
      )}
      {ReactDOM.createPortal(
        <CampsiteDetailsModal
          show={showCampsiteDetails}
          handleClose={() => setShowCampsiteDetails(false)}
          campsite={props.campsite}
        />,
        document.getElementById("view-campsite-details-modal")
      )}
      <li key={props.campsite.id} className="list-group-item">
        <Row>
          <Col sm={5}>
            <p className="lead mt-2"> {props.campsite.title}</p>
          </Col>

          <Col sm={7}>
            <BsTrashFill
              onClick={() => setShowDeleteConfirm(true)}
              className="text-danger float-right m-3"
              role="button"
            />

            {authCtx.role == 1 && (
              <Link
                className="text-secondary float-right mt-2"
                to={`/campsite/edit/${props.campsite.id}`}
                role="button"
              >
                <FaEdit />
              </Link>
            )}

            <CgDetailsMore
              onClick={() => setShowCampsiteDetails(true)}
              className="float-right m-3"
              role="button"
            />
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default CampsiteListItem;
