import { useContext } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Routes from "./pages/Routes";
import ViewRoute from "./pages/ViewRoute";
import Campsites from "./pages/Campsites";
import Layout from "./components/LayoutWrapper/Layout";
import NotFound from "./pages/NotFound";
import AuthContext from "../src/store/auth-context";
import AddCampsite from "./pages/AddCampsite";
import "mapbox-gl/dist/mapbox-gl.css";
import Forum from "./pages/Forum";
import EditCampsite from "./pages/EditCampsite";
import ForumTopic from "./pages/ForumTopic";
import ForumPost from "./pages/ForumPost";
import Manage from "./pages/Manage";
import "./pages/Home.css";
import Profile from "./pages/Profile";
import CovidInfo from "./pages/CovidInfo";

function App() {
  //Global State
  const authCtx = useContext(AuthContext);
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        {authCtx.isLoggedIn && (
          <Route path="/forum" exact>
            <Forum />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/forum/topic/:topicId">
            <ForumTopic />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/forum/post/:postId">
            <ForumPost />
          </Route>
        )}
        {authCtx.isLoggedIn && authCtx.role == 2 && (
          <Route path="/manage" exact>
            <Manage />
          </Route>
        )}
        {authCtx.isLoggedIn && authCtx.role == 0 && (
          <Route path="/routes" exact>
            <Routes />
          </Route>
        )}
        {authCtx.isLoggedIn && authCtx.role == 0 && (
          <Route path="/routes/:routeId">
            <ViewRoute />
          </Route>
        )}
        {authCtx.isLoggedIn && authCtx.role == 1 && (
          <Route path="/campsites" exact>
            <Campsites />
          </Route>
        )}
        {authCtx.isLoggedIn && authCtx.role == 1 && (
          <Route path="/campsites/add" exact>
            <AddCampsite />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/profile">
            <Profile />
          </Route>
        )}
        {authCtx.isLoggedIn && authCtx.role == 1 && (
          <Route path="/campsite/edit/:campsiteId">
            <EditCampsite />
          </Route>
        )}
        <Route path="/covid">
          <CovidInfo />
        </Route>
        <Route path="/notfound">
          <NotFound />
        </Route>
        <Route path="*">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
