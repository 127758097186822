import React, { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { Fragment } from "react";
import { FaUserPlus } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";

const RegisterForm = (props) => {
  //loading spinner
  const [isLoading, setIsLoading] = useState(false);

  //Validation errors
  const [validationErrors, setValidationErrors] = useState({});
  const [usernameError, setUsernameError] = useState();
  const [emailError, setEmailError] = useState();
  //Refs
  const emailInputRef = useRef();
  const usernameInputRef = useRef();
  //State
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const submitFormHandler = (event) => {
    //prevent refresh of page on submit
    event.preventDefault();
    //store data from form
    const usernamevalue = usernameInputRef.current.value;
    const emailValue = emailInputRef.current.value;
    //check and store value of checkbox
    const campsiteAccountValue = checkBoxState ? "true" : "false";
    //store all data in object
    const data = {
      username: usernamevalue,
      email: emailValue,
      password: password,
      campsiteAccount: campsiteAccountValue,
    };

    register(data);
  };

  const checkboxHandler = (event) => {
    //set value of checkbox
    setCheckBoxState(!checkBoxState);
  };

  const register = async (data) => {
    //Show loading spinner
    setIsLoading(true);
    //Set validation errors
    setValidationErrors({});
    setUsernameError();
    setEmailError();
    try {
      await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/user/register",
        data
      );
      //Hide loading spinner
      setIsLoading(false);
      //change to login tab in parent component
      props.success();
    } catch (error) {
      //Handler error
      error.response.data.usernameTaken &&
        setUsernameError(error.response.data.usernameTaken);

      error.response.data.emailTaken &&
        setEmailError(error.response.data.emailTaken);

      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      //Hide loading spinner
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={submitFormHandler}>
        <Form.Group controlId="register">
          <Form.Control
            type="text"
            placeholder="Username"
            ref={usernameInputRef}
          />
          {validationErrors.Username && (
            <span className="text-danger">{validationErrors.Username[0]}</span>
          )}
          {usernameError && (
            <span className="text-danger">{usernameError}</span>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Control type="email" placeholder="Email" ref={emailInputRef} />
          {validationErrors.Email && (
            <span className="text-danger">{validationErrors.Email[0]}</span>
          )}
          {emailError && <span className="text-danger">{emailError}</span>}
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <div className="row">
          <Form.Group className="col-6" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-6" controlId="formBasicConfirmPassword">
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              onChange={(event) => setPasswordConfirm(event.target.value)}
            />
          </Form.Group>
        </div>
        {validationErrors.Password && (
          <span className="text-danger">{validationErrors.Password[0]}</span>
        )}
        {(password !== passwordConfirm ||
          (password !== passwordConfirm && password != "")) && (
          <span className="text-danger">Passwords do not match</span>
        )}

        <Form.Group className="mt-1" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Campground Owner?"
            onChange={checkboxHandler}
          />
        </Form.Group>
        {isLoading && (
          <Spinner
            animation="border"
            variant="primary"
            className="float-right"
          />
        )}
        {!isLoading && (
          <Button
            className="float-right"
            variant="primary"
            type="submit"
            disabled={password !== passwordConfirm || (password == "" && true)}
          >
            Submit <FaUserPlus />
          </Button>
        )}
      </Form>
    </Fragment>
  );
};

export default RegisterForm;
