import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { RiSubtractFill } from "react-icons/ri";
import { BsChevronRight } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

const UserPostItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);

  //Toggle modal visibility
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const deletePost = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/post/delete/${props.post.id}`,
        authCtx.config
      );
      //Set the pagination page
      props.setSelectedPage(1);
      //Rerun all API requests
      authCtx.refreshData();
      //Toast notification
      toast.success("Post Deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Log Error
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={deletePost}
          header={"Delete Post"}
          message={
            props.post &&
            `Are you sure you want to delete the post - ${props.post.title}?`
          }
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li className="list-group-item">
        <Row>
          <Col sm={1}>
            <RiSubtractFill
              role="button"
              className="m-1"
              color="red"
              onClick={() => {
                setShowDeleteConfirmModal(true);
              }}
            />
          </Col>
          <Col sm={6}>{props.post.title}</Col>
          <Col sm={2}>{props.post.replies.length}</Col>
          <Col sm={2}>{props.post.upvotes.length}</Col>
          <Col sm={1}>
            <Link
              to={`/forum/post/${props.post.id}`}
              className="text-decoration-none m-1 float right"
            >
              <BsChevronRight className="m-1 float-right" color="black" />
            </Link>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default UserPostItem;
