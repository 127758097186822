import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ImageGallery from "react-image-gallery";

const ViewImageModal = (props) => {
  //State
  const [imageList, setImageList] = useState([]);

  //Create image list on load
  useEffect(() => {
    const createImageGalleryList = () => {
      const arry = [];
      //Format before passing data to slideshow component
      props.images.forEach((image) => {
        arry.push({ original: image.photoUrl, thumbnail: image.photoUrl });
      });
      setImageList(arry);
    };
    createImageGalleryList();
  }, [props.images]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Images</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ImageGallery items={imageList} showPlayButton={false} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-sm" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewImageModal;
