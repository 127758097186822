import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { Col, Row } from "react-bootstrap";
import UserPostItem from "./UserPostItem";
import axios from "axios";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const UserPosts = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Pagination State
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  //User posts data state
  const [userPosts, setUserPosts] = useState([]);

  //Set pagination current page
  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Run on page load, when the page number state changes and the global refresh state changes
  useEffect(() => {
    const getUserPosts = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/user/posts?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          authCtx.config
        );
        //Store user posts response
        setUserPosts(resp.data);
        //Get the pagination metadata in the header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        //Log Error
        console.log(error);
      }
    };
    getUserPosts();
  }, [authCtx.refresh, authCtx.config, pageNumber]);

  return (
    <React.Fragment>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <Row>
            <Col sm={1}></Col>
            <Col sm={6} className="font-weight-bold">
              Posts
            </Col>
            <Col sm={2} className="font-weight-bold">
              Replies
            </Col>
            <Col sm={2} className="font-weight-bold">
              Upvotes
            </Col>
            <Col sm={1}></Col>
          </Row>
        </li>
        {userPosts &&
          userPosts.map((post) => (
            <UserPostItem
              key={post.id}
              post={post}
              setSelectedPage={pageNumberHandler}
            />
          ))}
      </ul>
      <div className="d-flex justify-content-center">
        {paginationData && (
          <PaginationDisplay
            pagination={paginationData}
            pageSelected={pageNumberHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default UserPosts;
