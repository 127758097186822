import React from "react";
import { Col, Row } from "react-bootstrap";
import ForumPostItem from "./ForumPostItem";

const ForumPostList = (props) => {
  return (
    <React.Fragment>
      <div className="overflow-auto">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <Row>
              <Col sm={4} className="font-weight-bold">
                Title
              </Col>
              <Col sm={2} className="font-weight-bold">
                User
              </Col>
              <Col sm={3} className="font-weight-bold">
                Date Created
              </Col>
              <Col sm={1} className="font-weight-bold">
                Replies
              </Col>
              <Col sm={1} className="font-weight-bold">
                Upvotes
              </Col>
            </Row>
          </li>
          {props.posts &&
            props.posts.map((post) => (
              <ForumPostItem key={post.id} post={post} />
            ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default ForumPostList;
