import { useParams } from "react-router";
import React, { useState, useContext, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import AuthContext from "../store/auth-context";
import axios from "axios";
import ReplyList from "../components/Forum/ReplyList";
import { BiUpArrow } from "react-icons/bi";
import ReactDOM from "react-dom";
import ViewImageModal from "../components/Modal/Forum/ViewImageModal";
import { BsTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import { useHistory } from "react-router-dom";
import AddReplyModal from "../components/Modal/Forum/AddReplyModal";

const ForumPost = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Need to access paramaters in URL
  const params = useParams();
  //State
  const [post, setPost] = useState();
  const [upvoted, setUpvoted] = useState();
  //Modal Visibility State
  const [showImageModal, setShowImageModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showAddReplyModal, setShowAddReplyModal] = useState(false);
  //Need to redirect
  const history = useHistory();

  const deletePost = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/post/delete/${post.id}`,
        authCtx.config
      );
      //Toast Notification
      toast.success("Post Deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Redirect to forum page
      history.replace("/forum");
    } catch (error) {
      // Handle Error
      console.log(error.response);
    }
  };

  //Get post on page load and when refresh is toggled
  useEffect(() => {
    const getPost = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/post/${params.postId}`,
          authCtx.config
        );
        //Store response in post state
        setPost(resp.data);
      } catch (error) {
        // Handle Error
        //Redirect to not found
        history.replace("/notfound");
        console.log(error.response);
      }
    };
    getPost();
  }, [authCtx.refresh, authCtx.config, history, params.postId]);

  //check upvote status when post changes
  useEffect(() => {
    const checkUpvoteStatus = () => {
      //Loop through the upvotes and if the user has upvoted set upvoted state to true
      post.upvotes &&
        post.upvotes.forEach((upvote) => {
          if (upvote.username == authCtx.username) {
            setUpvoted(true);
          }
        });
    };
    post && checkUpvoteStatus();
  }, [post, authCtx.username]);

  const upvotePost = async () => {
    try {
      await axios.post(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/upvote/${post.id}`,
        {},
        authCtx.config
      );
      //Set upvoted state
      setUpvoted(true);
      //Reload API data
      authCtx.refreshData();
    } catch (error) {
      //Handle Error
      console.log(error.response);
    }
  };

  const removeUpvote = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/post/upvote/delete/${post.id}`,
        authCtx.config
      );
      //Set upvoted state
      setUpvoted(false);
      authCtx.refreshData();
    } catch (error) {
      //Handle error
      console.log(error.response);
    }
  };

  const upvoteHandler = () => {
    //If the post has been upvoted run remove request otherwise run add request
    upvoted ? removeUpvote() : upvotePost();
  };

  return (
    <Container fluid className="pl-3 pr-3">
      {post && (
        <React.Fragment>
          {ReactDOM.createPortal(
            <ViewImageModal
              show={showImageModal}
              handleClose={() => setShowImageModal(false)}
              images={post.images}
            />,
            document.getElementById("view-image-modal")
          )}
          {ReactDOM.createPortal(
            <DeleteConfirmModal
              show={showDeleteConfirmModal}
              handleClose={() => setShowDeleteConfirmModal(false)}
              delete={deletePost}
              header={"Delete Post"}
              message={
                post && `Are you sure you want to delete post - ${post.title}?`
              }
            />,
            document.getElementById("delete-confirm-modal")
          )}
          {ReactDOM.createPortal(
            <AddReplyModal
              show={showAddReplyModal}
              handleClose={() => setShowAddReplyModal(false)}
              postMessage={post.message}
            />,
            document.getElementById("add-reply-modal")
          )}

          <Row className="mt-3">
            <Col>
              <h3>
                {post.title}
                {(authCtx.role == 2 || authCtx.username == post.username) && (
                  <BsTrashFill
                    className="text-danger ml-2"
                    size=".8em"
                    role="button"
                    onClick={() => {
                      setShowDeleteConfirmModal(true);
                    }}
                  />
                )}
              </h3>
            </Col>
            <Col>
              <div className="float-right ml-2" style={{ color: "green" }}>
                +{post.upvotes.length}
              </div>

              <BiUpArrow
                role="button"
                color={`${upvoted ? "green" : "black"}`}
                size={"2em"}
                className="float-right"
                onClick={() => upvoteHandler()}
              />
            </Col>
          </Row>
          <div
            className="mt-4 mb-4 p-4 bg-light rounded
          "
          >
            <span className="text-info">{post.username}</span>
            <span className="font-italic float-right text-info">
              {post.createdOn.toString().slice(0, 10)}
            </span>
            <div
              className="text-left text-break mt-3 text-dark"
              style={{ whiteSpace: "pre-line" }}
            >
              {post.message}
            </div>
            <div className="float-right mb-3">
              {post.images.length > 0 && (
                <Button
                  variant="primary btn-sm"
                  onClick={() => setShowImageModal(true)}
                  images={post.images}
                >
                  View Image Attachment
                </Button>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center border-top border-light">
            <div className="w-75">
              <h3 className="h3 mt-3">
                Replies
                <span className="float-right">
                  <Button
                    onClick={() => setShowAddReplyModal(true)}
                    variant="primary btn-block mb-3"
                  >
                    Add Reply
                  </Button>
                </span>
              </h3>{" "}
              <ReplyList postId={post.id} />
            </div>
          </div>
        </React.Fragment>
      )}
    </Container>
  );
};

export default ForumPost;
