import React, { useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsTrashFill, BsChevronRight } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import ReactDOM from "react-dom";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditTopicModal from "../Modal/Forum/EditTopicModal";

const TopicListItem = (props) => {
  //Toggle modal visibility
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  //Global state
  const authCtx = useContext(AuthContext);

  const deleteTopic = async () => {
    try {
      const resp = await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/topic/delete/${props.topic.id}`,
        authCtx.config
      );
      //Set the selected pagination page to 1
      props.setSelectedPage(1);
      //Rerun all API requests
      authCtx.refreshData();
      //Toast notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      // Handle Error
      error.response.data.message &&
        toast.success(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      //Log response
      console.log(error.response);
    }
  };
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={deleteTopic}
          header={"Delete Topic"}
          message={
            props.topic &&
            `Are you sure you want to delete discussion topic ${props.topic.title}?`
          }
        />,
        document.getElementById("delete-confirm-modal")
      )}
      {ReactDOM.createPortal(
        <EditTopicModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          topic={props.topic}
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li className="list-group-item">
        <Row className="text-dark">
          <Col sm={8}>{props.topic.title}</Col>
          <Col sm={2}>{props.topic.posts.length}</Col>
          <Col>
            <div className="float-right">
              {authCtx.role == 2 && (
                <span>
                  <BsTrashFill
                    role="button"
                    className="text-danger"
                    onClick={() => {
                      setShowDeleteConfirmModal(true);
                    }}
                  />
                  <FaEdit
                    role="button"
                    className="text-secondary mr-3 ml-3"
                    onClick={() => setShowEditModal(true)}
                  />
                </span>
              )}
              <Link
                to={`/forum/topic/${props.topic.id}`}
                className="text-decoration-none"
              >
                <BsChevronRight color="black" />
              </Link>
            </div>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default TopicListItem;
