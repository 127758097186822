import React, { useState, useEffect, useContext } from "react";
import ReplyListItem from "./ReplyListItem";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const ReplyList = (props) => {
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [replies, setReplies] = useState();

  const authCtx = useContext(AuthContext);

  //Update the selected pagination page
  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Get replies on page load, if the page number is changed or the global refresh state is toggled
  useEffect(() => {
    const getReplies = async () => {
      //URL requests data depeding on state value
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/replies/${props.postId}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          authCtx.config
        );
        setReplies(resp.data);
        //Access the header container the pagination metadata and parse so it can be accessed
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        // Log error
        console.log(error.response);
      }
    };
    getReplies();
  }, [authCtx.refresh, authCtx.config, pageNumber, props.postId]);

  return (
    <React.Fragment>
      <ul className="list-group list-group-flush">
        {replies &&
          replies.map((reply) => (
            <ReplyListItem
              key={reply.id}
              reply={reply}
              setSelectedPage={pageNumberHandler}
            />
          ))}
      </ul>
      <div className="d-flex justify-content-center">
        {paginationData && (
          <PaginationDisplay
            pagination={paginationData}
            pageSelected={pageNumberHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ReplyList;
