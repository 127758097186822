import React, { useContext, useRef, useState } from "react";
import { Col, Row, InputGroup, FormControl } from "react-bootstrap";
import { RiSubtractFill } from "react-icons/ri";
import { useEffect } from "react";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CostItem = (props) => {
  //Refs
  const costRef = useRef();
  const titleRef = useRef();

  //Global state
  const authCtx = useContext(AuthContext);

  //Validation state
  const [validationErrors, setValidationErrors] = useState({});

  //Reset validation when toggled in parent component
  useEffect(() => {
    setValidationErrors({});
  }, [props.clearValidation]);

  const updateCost = async (data) => {
    //Reset validation errors
    setValidationErrors({});
    try {
      await axios.put(
        `https://api-roadtrip-resource.azure-api.net/v1/api/route/updateCost/${props.cost.id}`,
        data,
        authCtx.config
      );
      //Rerun API requests
      authCtx.refreshData();
    } catch (error) {
      //Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
    }
  };

  const removeCost = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/route/deleteCost/${props.cost.id}`,
        authCtx.config
      );
      //Rerun API Requests
      authCtx.refreshData();
    } catch (error) {
      // Handle Error
      error.response &&
        error.response.data &&
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      console.log(error.response);
    }
  };

  const updateCostHandler = () => {
    //Get the ref values
    const titleValue = titleRef.current.value;
    const costValue = costRef.current.value;

    //Create data object
    const data = { title: titleValue, amount: costValue };
    updateCost(data);
  };

  return (
    <React.Fragment>
      {props.cost && (
        <li className="list-group-item">
          <Row>
            <Col sm={1}>
              <RiSubtractFill
                role="button"
                className="m-1 mt-2"
                color="red"
                onClick={() => removeCost()}
              />
            </Col>
            <Col sm={11}>
              <InputGroup>
                <FormControl
                  role="button"
                  placeholder="Item"
                  defaultValue={props.cost.title}
                  onBlur={() => updateCostHandler()}
                  ref={titleRef}
                />
                <span
                  className="font-weight-bold pl-2 pr-1"
                  style={{ fontSize: "1.3em" }}
                >
                  £
                </span>
                <FormControl
                  role="button"
                  type="number"
                  min="0.00"
                  step=".01"
                  defaultValue={props.cost.amount}
                  onBlur={() => updateCostHandler()}
                  ref={costRef}
                  // onChange={(event) => setCost(event.target.value)}
                />
              </InputGroup>
              {validationErrors.Title && (
                <span className="text-danger">{validationErrors.Title[0]}</span>
              )}
              {validationErrors.Amount && (
                <span className="text-danger">
                  {validationErrors.Amount[0]}
                </span>
              )}
            </Col>
          </Row>
        </li>
      )}
    </React.Fragment>
  );
};

export default CostItem;
