import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { TiTick, TiTimes } from "react-icons/ti";

const CampsiteDetails = (props) => {
  //Images state
  const [images, setImages] = useState([]);

  //On load create an array of the campsite images in the format accepted by the image gallery
  useEffect(() => {
    const arry = [];
    props.campsite.images &&
      props.campsite.images.forEach((image) => {
        arry.push({ original: image.photoUrl });
      });
    setImages(arry);
  }, [props.campsite.images]);

  return (
    <React.Fragment>
      <div className="border rounded p-3">
        <Row>
          <Col>
            <h3 className="h3 text-success">{props.campsite.title}</h3>
            <p className="text-left text-break mt-3 text-secondary">
              {props.campsite.description}
            </p>
            <Row className="m-3">
              <Col>
                <p>
                  Dog Friendly{" "}
                  {props.campsite.dogFriendly ? (
                    <TiTick color="green" />
                  ) : (
                    <TiTimes color="red" />
                  )}
                </p>
                <p>
                  Electric{" "}
                  {props.campsite.electric ? (
                    <TiTick color="green" />
                  ) : (
                    <TiTimes color="red" />
                  )}
                </p>
                <p>
                  Wifi{" "}
                  {props.campsite.wifi ? (
                    <TiTick color="green" />
                  ) : (
                    <TiTimes color="red" />
                  )}
                </p>
              </Col>
              <Col>
                <p>
                  Water Point{" "}
                  {props.campsite.drinkingWaterPoint ? (
                    <TiTick color="green" />
                  ) : (
                    <TiTimes color="red" />
                  )}
                </p>
                <p>
                  WC{" "}
                  {props.campsite.wc ? (
                    <TiTick color="green" />
                  ) : (
                    <TiTimes color="red" />
                  )}
                </p>
                <p>
                  Chemical Disposal{" "}
                  {props.campsite.chemicalDisposal ? (
                    <TiTick color="green" />
                  ) : (
                    <TiTimes color="red" />
                  )}
                </p>
              </Col>
            </Row>

            <Row>
              <dt className="col-3">
                <p className="text-info">Tel No: </p>
              </dt>
              <dd className="col-9 text-dark">{props.campsite.contactTelNo}</dd>

              <dt className="col-3">
                <p className="text-info">Email: </p>
              </dt>
              <dd className="col-9 text-dark">{props.campsite.contactEmail}</dd>

              <dt className="col-3">
                <p className="text-info">Website: </p>
              </dt>
              <dd className="col-9 text-dark">{props.campsite.webAddress}</dd>

              <dt className="col-3">
                <p className="text-info">Address: </p>
              </dt>
              <dd className="col-9 text-dark">{props.campsite.address}</dd>
            </Row>
          </Col>
          <Col>
            <ImageGallery
              items={images}
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
            />
            <Row className="mt-3">
              <dt className="col-6">
                <p className="text-info">High Season Average Cost per Day: </p>
              </dt>
              <dd className="col-6 text-dark">
                £ {props.campsite.highSeasonCost}
              </dd>
              <dt className="col-6">
                <p className="text-info">Low Season Average Cost per Day: </p>
              </dt>
              <dd className="col-6 text-dark">
                £ {props.campsite.lowSeasonCost}
              </dd>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CampsiteDetails;
