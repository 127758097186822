import React, { useContext, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import UserActivity from "../components/Forum/UserActivity";
import ChangePassword from "../components/Profile/ChangePassword";
import Details from "../components/Profile/Details";
import AuthContext from "../store/auth-context";
import { BsTrashFill } from "react-icons/bs";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Profile = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Modal Visibility State
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const deleteUserAccount = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/user/delete?username=${authCtx.username}`,
        authCtx.config
      );
      //Toast Notification
      toast.success("User Deleted", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Logout
      authCtx.logout();
    } catch (error) {
      // Handle Error
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={deleteUserAccount}
          header={"Delete User Account"}
          message={`Are you sure you want to delete your account?`}
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <Container fluid className="pr-3 pl-3">
        <Row className="p-3">
          <Col>
            <h1 className="h1">{authCtx.username}'s Profile </h1>
          </Col>
          {authCtx.role !== 2 && (
            <Col>
              <div className="float-right">
                Delete Account
                <BsTrashFill
                  className="m-1"
                  color="red"
                  onClick={() => setShowDeleteConfirmModal(true)}
                  role="button"
                />
              </div>
            </Col>
          )}
        </Row>

        <span>
          <Alert variant="danger">Beware - All Changes are Permanent</Alert>
        </span>
        <Row>
          <Col sm={6}>
            <h3 className="h3 m-2">My Details</h3>
            <Details />
          </Col>
          <Col sm={4}>
            <h3 className="h3 m-2">Change Password</h3>
            <ChangePassword />
          </Col>
        </Row>
        <div style={{ paddingTop: "75px" }}>
          <h3 className="h3 m-2">Forum Activity</h3>
          <UserActivity />
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Profile;
