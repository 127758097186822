import * as React from "react";
import { useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import "./CampsiteLocationMap.css";
import { HiLocationMarker } from "react-icons/hi";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxWorker from "mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const CampsiteLocationMap = (props) => {
  //The initial viewport
  const [viewport, setViewport] = useState({
    width: 400,
    height: 250,
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 10,
  });

  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
      mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      width="100%"
      // Update the map when longitude and latitude changes
      longitude={props.longitude}
      latitude={props.latitude}
    >
      <Marker
        latitude={props.latitude}
        longitude={props.longitude}
        offsetLeft={-15}
        offsetTop={-31}
      >
        <HiLocationMarker className="text-dark Marker" />
      </Marker>
    </ReactMapGL>
  );
};

export default CampsiteLocationMap;
