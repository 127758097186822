import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

const InfoIcon = ({ message }) => {
  const renderTooltip = (props) => (
    //Show the props message in the tooltip
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <AiOutlineInfoCircle color="black" size="1.38em" role="button" />
    </OverlayTrigger>
  );
};

export default InfoIcon;
