import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { Col, Row } from "react-bootstrap";
import UserReplyItem from "./UserReplyItem";
import axios from "axios";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const UserReplies = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //User Replies State
  const [userReplies, setUserReplies] = useState();
  //Pagination State
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  //Set the pagination page
  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Get replies on page load, when the page number state changes and when the global refresh has been toggled
  useEffect(() => {
    const getUserReplies = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/user/replies?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          authCtx.config
        );
        //Store the user replies data
        setUserReplies(resp.data);
        //Get the pagination metadata from the header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        //Log Error
        console.log(error);
      }
    };
    getUserReplies();
  }, [authCtx.refresh, authCtx.config, pageNumber]);

  return (
    <React.Fragment>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <Row>
            <Col sm={1}></Col>
            <Col sm={8} className="font-weight-bold">
              Post
            </Col>
            <Col sm={2} className="font-weight-bold">
              Date
            </Col>
          </Row>
        </li>
        {userReplies &&
          userReplies.map((reply) => (
            <UserReplyItem
              key={reply.id}
              reply={reply}
              setSelectedPage={pageNumberHandler}
            />
          ))}
      </ul>
      <div className="d-flex justify-content-center">
        {paginationData && (
          <PaginationDisplay
            pagination={paginationData}
            pageSelected={pageNumberHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default UserReplies;
