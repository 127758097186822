import React, { useContext, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationItem = (props) => {
  //Global state
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);

  const inviteAcceptDecline = async (data) => {
    //Show spinner
    setIsLoading(true);
    try {
      const resp = await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/route/inviteDecision",
        data,
        authCtx.config
      );
      //Toast notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //If there is only one notification close the modal
      props.noOfNotification === 1 && props.handleClose();
      //Refresh API data
      authCtx.refreshData();
      //Hide spinner
      setIsLoading(false);
    } catch (error) {
      //Handle error
      error.response &&
        error.response.data &&
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      //Hide spinner
      setIsLoading(false);
    }
  };
  return (
    <li className="list-group-item border-bottom">
      <Row>
        <Col sm={6}>
          Invite to Road Trip:{" "}
          <span className="font-weight-bold">{props.invite.route.title}</span>
        </Col>
        {!isLoading && (
          <Col sm={3}>
            <Button
              variant="danger"
              onClick={() =>
                inviteAcceptDecline({
                  inviteId: props.invite.id,
                  accept: false,
                })
              }
            >
              Decline
            </Button>
          </Col>
        )}
        {!isLoading && (
          <Col sm={3}>
            <Button
              variant="success"
              onClick={() =>
                inviteAcceptDecline({ inviteId: props.invite.id, accept: true })
              }
            >
              Accept
            </Button>
          </Col>
        )}
        {isLoading && (
          <Spinner animation="border" variant="dark" className="float-right" />
        )}
      </Row>
    </li>
  );
};

export default NotificationItem;
