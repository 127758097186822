import React from "react";
import { Button, Modal, Form } from "react-bootstrap";

const DeleteConfirmModal = (props) => {
  //On form submit prevent refresh, handle the delete in the parent component and close the modal
  const formSubmit = (event) => {
    event.preventDefault();
    props.delete();
    props.handleClose();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">{props.header}</Modal.Title>
        </Modal.Header>{" "}
        <Form onSubmit={formSubmit}>
          <Modal.Body>
            <Form.Group controlId="formMessage">
              <Form.Label>{props.message}</Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary btn-sm" onClick={props.handleClose}>
              Close
            </Button>
            <Button variant="danger btn-sm" type="submit">
              Confirm
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteConfirmModal;
