import React from "react";
import { Container, Card } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import Logo from "./UI_Homepage.png";

//https://stackoverflow.com/questions/61274798/how-to-display-an-image-in-full-screen-background-using-reactjs-and-css
const Home = () => {
  return (
    <Container className="p-0" fluid={true}>
      <div className="main-image" style={{ height: "70vh" }}></div>
      <Row className="mt-3">
        <Col sm={4}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Plan a Special Road Trip</Card.Title>
              <Card.Text>
                Use our specialised road trip planner and set out on the trip of
                a lifetime!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Join our Forum</Card.Title>
              <Card.Text>
                Struggling to find places to add to your trip? Check out the
                forum and gain inspiration from other members.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Campground Owner?</Card.Title>
              <Card.Text>
                Advertise your campsite for free and allow users to add to their
                trips! Register to get started.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pattern w-100 mt-3 pt-3 pb-3">
        <Col md={6}>
          <img className="img-fluid" alt="" src={Logo} />
        </Col>
        <Col md={6}>
          <h1 className="h1">Why use Camper Planner?</h1>
          <p className="lead">
            Our website aims to make planning travel for by campervan as stress
            free as possible. Here are some features of our planner!
          </p>
          <ul className="lead">
            <li>Ability to add Campsite or Location Route Points</li>
            <li>Keep track of costs using the costs section</li>
            <li>Invite other users to help plan your trip</li>
            <li>
              Search for Local Attractions, Points of Interest, Toilets or
              Tourist Information
            </li>
          </ul>
          <p className="lead">
            All presented on a beautiful map. Get planning today!
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
