import React, { Fragment, useState, useContext, useEffect } from "react";
import RouteList from "../components/Route/RouteList";
import { FaPlusCircle } from "react-icons/fa";
import AddRouteModal from "../components/Modal/Route/AddRouteModal";
import ReactDOM from "react-dom";
import { Row, Col, Container, Button, Alert, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import AuthContext from "../store/auth-context";
import RouteAccessList from "../components/Route/RouteAccessList";
import ForumNavigateCard from "../components/UI/ForumNavigateCard";

const Routes = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Modal Visibility State
  const [showAddRouteModal, setShowAddRouteModal] = useState(false);
  //Store route data
  const [routes, setRoutes] = useState();
  const [routesAccess, setRoutesAccess] = useState();

  //Get data on page load or when refresh is toggled
  useEffect(() => {
    const getRoutes = async (data) => {
      try {
        const resp = await axios.get(
          "https://api-roadtrip-resource.azure-api.net/v1/api/route/getroutes",
          authCtx.config
        );
        setRoutes(resp.data);
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    const getRoutesAccess = async (data) => {
      try {
        const resp = await axios.get(
          "https://api-roadtrip-resource.azure-api.net/v1/api/route/userAccessRoutes",
          authCtx.config
        );
        setRoutesAccess(resp.data);
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    getRoutesAccess();
    getRoutes();
  }, [authCtx.refresh, authCtx.config]);

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <AddRouteModal
          show={showAddRouteModal}
          handleClose={() => setShowAddRouteModal(false)}
        />,
        document.getElementById("add-route-modal")
      )}
      <div>
        <Container fluid className="pr-3 pl-3">
          <Row className="mb-5" style={{ height: "90vh" }}>
            <Col sm={9}>
              <Row className="mt-2 mb-2">
                <Col>
                  <h1 className="h1">Road Trips</h1>
                </Col>
                <Col sm={3}>
                  <Button
                    onClick={() => setShowAddRouteModal(true)}
                    className="btn btn-primary btn-sm float-right mt-2"
                  >
                    New <FaPlusCircle />
                  </Button>
                </Col>
                <Col sm={12}>
                  <Alert variant="info">
                    Start planning a new trip! Delete your trip, change it's
                    name or view the route on our interactive interface by
                    clicking the arrow! View trips you are part of in the joint
                    trip tab.
                  </Alert>
                </Col>
                <Col>
                  <div>
                    <Tabs
                      defaultActiveKey="userRoadTrips"
                      id="route-tab"
                      className="mb-3 mt-3"
                      fill
                    >
                      <Tab
                        eventKey="userRoadTrips"
                        title="My Road Trips"
                        tabClassName="text-dark"
                      >
                        <div style={{ height: "500px" }}>
                          <RouteList routes={routes} />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="routeAccessTrips"
                        title="Joint Trips"
                        tabClassName="text-dark"
                      >
                        <div style={{ height: "500px" }}>
                          <RouteAccessList routesAccess={routesAccess} />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <div className="mt-3">
                <Alert variant="info">
                  <img
                    alt=""
                    className="img-fluid"
                    src="https://irp-cdn.multiscreensite.com/47183c04/dms3rep/multi/desktop/campervan-hire-campsite-motorhme-rental-718x395.jpg"
                  />
                  <img
                    alt=""
                    className="img-fluid"
                    src="https://thegapdecaders.com/wp-content/uploads/2020/07/freecampingFI12x6-800x500.jpg"
                  />
                  <img
                    alt=""
                    className="img-fluid"
                    src="https://images.campsites.co.uk/pagedata/13212/3ded083d-dfca-4607-b5bd-14782a58e503/1362/600/either/caravan-and-motorhome-club-cam.jpg"
                  />
                </Alert>
              </div>
            </Col>
            <Col sm={12}>
              <ForumNavigateCard />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Routes;
