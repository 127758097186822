import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import AuthContext from "../../../store/auth-context";
import YouTube from "react-youtube";

const HelpModal = (props) => {
  //Global state
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Help</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ height: "300px" }} className="overflow-auto">
            <h6 className="pb-2">
              Watch the tutorials to help use the website!
            </h6>
            {authCtx.role != 1 && (
              <div>
                <p className="font-weight-bold">Route Planning</p>
                <YouTube
                  videoId="OA-zWJ3DXmc"
                  opts={{ height: "220px" }}
                  className="w-100"
                ></YouTube>
              </div>
            )}

            {authCtx.role != 0 && (
              <div>
                <p className="font-weight-bold">Campsite Management</p>
                <YouTube
                  videoId="kfPfpv7sxPg"
                  opts={{ height: "220px" }}
                  className="w-100"
                ></YouTube>
              </div>
            )}

            <p className="font-weight-bold">Forum</p>
            <YouTube
              videoId="pq4i2At6fBc"
              opts={{ height: "220px" }}
              className="w-100"
            ></YouTube>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpModal;
