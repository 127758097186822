import React, { useRef, useState, useContext } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../../store/auth-context";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CampsiteDetails from "../../ViewDetails/CampsiteDetails";

const ViewEditCampsiteModal = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //Validation State
  const [validationErrors, setValidationErrors] = useState({});
  //Refs
  const notesRef = useRef();
  //State, set default value
  const [date, setDate] = useState(
    new Date(props.routepoint.arrivalDate.toString())
  );

  const updateCampsite = async (data) => {
    //Show spinner
    setIsLoading(true);
    //Reset validation errors
    setValidationErrors({});
    try {
      await axios.put(
        `https://api-roadtrip-resource.azure-api.net/v1/api/routepoint/update/${props.routepoint.id}`,
        data,
        authCtx.config
      );
      //Reload API data
      authCtx.refreshData();
      //Toast Notification
      toast.success("Campsite Updated!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Hide spinner
      setIsLoading(false);
      //Close modal
      props.handleClose();
    } catch (error) {
      //Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  const formSubmit = (event) => {
    //Prevent refresh
    event.preventDefault();
    const notesValue = notesRef.current.value;
    //Create data and add form values
    const data = {
      arrivalDate: date.toISOString(),
      notes: notesValue,
    };
    updateCampsite(data);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Campsite</Modal.Title>
        </Modal.Header>{" "}
        <Form onSubmit={formSubmit}>
          <Modal.Body>
            <Row>
              <Col className="col-4">
                <Form.Group controlId="formDate">
                  <Form.Label>Arrival Date</Form.Label>{" "}
                  <Form.Control
                    defaultValue={props.routepoint.arrivalDate
                      .toString()
                      .substring(0, 16)}
                    type="datetime-local"
                    onBlur={(event) => setDate(new Date(event.target.value))}
                    required
                  />
                  {validationErrors.ArrivalDate && (
                    <span className="text-danger">
                      {validationErrors.ArrivalDate[0]}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col className="col-8">
                <Form.Group as={Row} controlId="formDescription">
                  <Form.Label column sm="1">
                    Notes
                  </Form.Label>
                  <Col className="col-11">
                    <Form.Control
                      as="textarea"
                      defaultValue={props.routepoint.notes}
                      rows={2}
                      ref={notesRef}
                    />
                    {validationErrors.Notes && (
                      <span className="text-danger">
                        {validationErrors.Notes[0]}
                      </span>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <CampsiteDetails campsite={props.routepoint.campsite} />
          </Modal.Body>
          <Modal.Footer>
            {!isLoading && (
              <Button
                variant="secondary btn-sm"
                onClick={() => {
                  props.handleClose();
                  setValidationErrors({});
                }}
              >
                Close
              </Button>
            )}

            {!isLoading && (
              <Button variant="primary btn-sm" type="submit">
                Save Changes
              </Button>
            )}

            {isLoading && (
              <Spinner
                animation="border"
                variant="primary"
                className="float-right"
              />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ViewEditCampsiteModal;
