import React, { useContext, useState } from "react";
import { Form, Row, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Loading spinner visibility
  const [isLoading, setIsLoading] = useState(false);
  //State
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  //Validation State
  const [validationErrors, setValidationErrors] = useState({});

  const submitHandler = () => {
    //Create data object container password and new password
    const data = { password, newPassword };
    changePassword(data);
  };

  const changePassword = async (data) => {
    //Show spinner
    setIsLoading(true);
    //Clear validation errors
    setValidationErrors({});
    try {
      const resp = await axios.put(
        `https://api-roadtrip-resource.azure-api.net/v1/api/user/changePassword?username=${authCtx.username}`,
        data,
        authCtx.config
      );
      //Clear the input fields
      Array.from(document.querySelectorAll("input")).map(
        (item) => (item.value = "")
      );
      //Reset the password state
      setPassword("");
      setNewPassword("");
      //Hide spinner
      setIsLoading(false);
      //Toast notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);

      error.response.data &&
        error.response.data.message &&
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  return (
    <Form className="p-3" onSubmit={submitHandler}>
      <Row>
        <Form.Label className="col-4">Password</Form.Label>
        <Form.Group className="col-8" controlId="password">
          <Form.Control
            type="password"
            onChange={(event) => setPassword(event.target.value)}
          />
        </Form.Group>
        <Form.Label className="col-4">New Password</Form.Label>
        <Form.Group className="col-8" controlId="newPassword">
          <Form.Control
            type="password"
            onChange={(event) => setNewPassword(event.target.value)}
          />
        </Form.Group>
        {validationErrors.NewPassword && (
          <span className="text-danger">{validationErrors.NewPassword[0]}</span>
        )}
      </Row>
      {!isLoading && (
        <Button
          className="float-right"
          variant="primary btn-sm"
          onClick={submitHandler}
        >
          Update
        </Button>
      )}
      {isLoading && (
        <Spinner animation="border" variant="primary" className="float-right" />
      )}
    </Form>
  );
};

export default ChangePassword;
