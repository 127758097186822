import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import LoginRegisterModal from "../Modal/LoginRegister/LoginRegisterModal";
import ReactDOM from "react-dom";
import { AiOutlineNotification } from "react-icons/ai";
import NotificationsModal from "../Modal/Notifications/NotificationsModal";
import axios from "axios";
import Logo from "./CamperPlannerLogo.png";
import { BiHelpCircle } from "react-icons/bi";
import HelpModal from "../Modal/Help/HelpModal";

const NavBar = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Redirect will be needed
  const history = useHistory();
  //Modal visibility state
  const [showLoginRegisterModal, setShowLoginRegisterModal] = useState(false);
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  //Easier readability of code
  const role = authCtx.role;
  const isLoggedIn = authCtx.isLoggedIn;
  //Store invite data
  const [invites, setInvites] = useState();

  const getInvites = async () => {
    try {
      const resp = await axios.get(
        `https://api-roadtrip-resource.azure-api.net/v1/api/route/getUserInvites`,
        authCtx.config
      );
      setInvites(resp.data);
    } catch (error) {
      // Handle Error
      console.log(error.response);
    }
  };

  var intervalId;
  useEffect(() => {
    //get invites only if logged in and standard user
    //reload data every 21 seconds
    authCtx.isLoggedIn &&
      authCtx.role == 0 &&
      getInvites() &&
      // eslint-disable-next-line
      (intervalId = setInterval(() => getInvites(), 21000));
    //When component unmounts clear the invervalId
    return () => {
      clearInterval(intervalId);
    };
  }, [authCtx.refresh, authCtx.token]);

  //On logout clear user data through global state
  const logoutHandler = () => {
    authCtx.logout();
    //Redirect to home
    history.replace("/home");
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <LoginRegisterModal
          show={showLoginRegisterModal}
          handleClose={() => setShowLoginRegisterModal(false)}
        />,
        document.getElementById("login-register-modal")
      )}
      {isLoggedIn &&
        ReactDOM.createPortal(
          <HelpModal
            show={showHelpModal}
            handleClose={() => setShowHelpModal(false)}
          />,
          document.getElementById("help-modal")
        )}
      {invites &&
        ReactDOM.createPortal(
          <NotificationsModal
            show={showNotificationsModal}
            handleClose={() => setShowNotificationsModal(false)}
            invites={invites}
          />,
          document.getElementById("notifications-modal")
        )}
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <NavLink
            className="nav-link"
            style={{ color: "lightgrey" }}
            to="/home"
          >
            <img src={Logo} alt="logo" style={{ height: "33px" }} />
          </NavLink>

          <Navbar.Toggle />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {isLoggedIn && role == 0 && (
                <NavLink
                  className="nav-link"
                  style={{ color: "lightgrey" }}
                  to="/routes"
                >
                  Routes
                </NavLink>
              )}
              {isLoggedIn && role == 2 && (
                <NavLink
                  className="nav-link"
                  style={{ color: "lightgrey" }}
                  to="/manage"
                >
                  Manage
                </NavLink>
              )}

              {isLoggedIn && role == 1 && (
                <NavLink
                  className="nav-link"
                  style={{ color: "lightgrey" }}
                  to="/campsites"
                >
                  Campsites
                </NavLink>
              )}

              {authCtx.isLoggedIn && (
                <NavLink
                  className="nav-link"
                  style={{ color: "lightgrey" }}
                  to="/forum"
                >
                  Forum
                </NavLink>
              )}

              <NavLink className="nav-link text-warning" to="/covid/statistics">
                Covid Info
              </NavLink>
            </Nav>
            {!isLoggedIn && (
              <Nav>
                <Nav.Link
                  className="nav-link"
                  style={{ color: "lightgrey" }}
                  onClick={() => setShowLoginRegisterModal(true)}
                >
                  Login/Register
                </Nav.Link>
              </Nav>
            )}
            {isLoggedIn && (
              <Nav>
                <Button
                  variant="warning"
                  className="mr-3 btn-sm text-dark"
                  onClick={() => setShowHelpModal(true)}
                >
                  Help <BiHelpCircle size="1.3em" />
                </Button>
                {authCtx.role == 0 && (
                  <Button
                    variant="light"
                    className="mr-3 btn-sm text-info"
                    onClick={() => setShowNotificationsModal(true)}
                  >
                    {/* Show the number of invites */}
                    {invites ? invites.length : "0"} Notifications
                    <AiOutlineNotification size="1.3em" />
                  </Button>
                )}

                <NavLink className="nav-link text-warning" to="/profile">
                  {authCtx.username} Profile
                </NavLink>
                <span
                  role="button"
                  onClick={logoutHandler}
                  className="nav-link"
                  style={{ color: "lightgrey" }}
                >
                  Logout
                </span>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default NavBar;
