import React, { Fragment, useState, useContext, useEffect } from "react";
import { FaPlusCircle } from "react-icons/fa";
import CampsiteList from "../../src/components/Campsite/CampsiteList";
import axios from "axios";
import AuthContext from "../store/auth-context";
import { Row, Col, Container, Alert } from "react-bootstrap";
import ForumNavigateCard from "../components/UI/ForumNavigateCard";
import { Link } from "react-router-dom";

const Campsites = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //State to store campsite data
  const [campsites, setCampsites] = useState();

  //Run the API request on page load or when global refresh state is toggled
  useEffect(() => {
    const getUserCampsites = async () => {
      try {
        const resp = await axios.get(
          "https://api-roadtrip-resource.azure-api.net/v1/api/campsite/getusercampsites",
          authCtx.config
        );
        //store response in campsite state
        setCampsites(resp.data);
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    getUserCampsites();
  }, [authCtx.refresh, authCtx.config]);

  return (
    <Fragment>
      <Container fluid className="pr-3 pl-3">
        <Row className="mb-5" style={{ height: "90vh" }}>
          <Col sm={9}>
            <Row>
              <Col>
                <h1 className="h1">Manage Campsites</h1>
              </Col>
              <Col>
                <Link
                  className="btn btn-primary btn-sm float-right mt-2"
                  to={`/campsites/add`}
                >
                  New <FaPlusCircle />
                </Link>
              </Col>
              <Col sm={12}>
                <Alert variant="info">
                  Add, Delete or Update your Campsites!
                </Alert>
              </Col>

              <Col>
                <div style={{ height: "500px" }}>
                  <CampsiteList campsites={campsites} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <div className="mt-3">
              <Alert variant="info">
                <img
                  alt=""
                  className="img-fluid"
                  src="https://irp-cdn.multiscreensite.com/47183c04/dms3rep/multi/desktop/campervan-hire-campsite-motorhme-rental-718x395.jpg"
                />
                <img
                  alt=""
                  className="img-fluid"
                  src="https://thegapdecaders.com/wp-content/uploads/2020/07/freecampingFI12x6-800x500.jpg"
                />
                <img
                  alt=""
                  className="img-fluid"
                  src="https://images.campsites.co.uk/pagedata/13212/3ded083d-dfca-4607-b5bd-14782a58e503/1362/600/either/caravan-and-motorhome-club-cam.jpg"
                />
              </Alert>
            </div>
          </Col>
          <Col sm={12}>
            <ForumNavigateCard />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Campsites;
