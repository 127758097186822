import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { RiSubtractFill } from "react-icons/ri";
import { BsChevronRight } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import ReactDOM from "react-dom";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";

const UserUpvoteItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Toggle Modal Visibility
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const removeUpvote = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/post/upvote/delete/${props.post.id}`,
        authCtx.config
      );
      //Set selected pagination page to 1
      props.setSelectedPage(1);
      //Rerun API requests
      authCtx.refreshData();
      //Toast Notification
      toast.success("Upvote Removed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      //Log response
      console.log(error.response);
    }
  };
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={removeUpvote}
          header={"Delete Reply"}
          message={
            props.post &&
            `Are you sure you want to remove the upvote on post - ${props.post.title}?`
          }
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li className="list-group-item">
        <Row>
          <Col sm={1}>
            <RiSubtractFill
              role="button"
              className="m-1"
              color="red"
              onClick={() => setShowDeleteConfirmModal(true)}
            />
          </Col>
          <Col sm={10}>{props.post.title}</Col>
          <Col sm={1}>
            <Link
              to={`/forum/post/${props.post.id}`}
              className="text-decoration-none m-1 float right"
            >
              <BsChevronRight className="m-1 float-right" color="black" />
            </Link>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default UserUpvoteItem;
