import React from "react";
import CampsiteListItem from "./CampsiteListItem";

const CampsiteList = (props) => {
  return (
    <React.Fragment>
      {props.campsites && (
        <ul className="w-100 h-100 overflow-auto list-group list-group-flush">
          {props.campsites.map((campsite) => (
            <CampsiteListItem key={campsite.id} campsite={campsite} />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default CampsiteList;
