import React, { useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import { BsTrashFill } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import ReactDOM from "react-dom";

const ReplyListItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);

  //Toggle modal view
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const deleteReply = async () => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/forum/reply/delete/${props.reply.id}`,
        authCtx.config
      );
      //When a reply is deleted set the selected pagination page to 1
      props.setSelectedPage(1);
      //Rerun all API requests
      authCtx.refreshData();
      //Toast notification
      toast.success("Reply Deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      // Log error
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          delete={deleteReply}
          header={"Delete Reply"}
          message={
            props.reply &&
            `Are you sure you want to delete the message - ${
              props.reply.message.length > 60
                ? props.reply.message.substring(0, 60) + "..."
                : props.reply.message
            }?`
          }
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li className="list-group-item mt-2">
        <div>
          <span className="text-info">{props.reply.username}</span>
          <span className="font-italic float-right text-info">
            {props.reply.createdOn.toString().slice(0, 10)}
          </span>
        </div>
        <div
          className="text-left text-break mt-3 text-dark"
          style={{ "white-space": "pre-line" }}
        >
          {props.reply.message}
        </div>
        {(authCtx.role == 2 || authCtx.username == props.reply.username) && (
          <BsTrashFill
            role="button"
            className="text-danger float-right"
            onClick={() => {
              setShowDeleteConfirmModal(true);
            }}
          />
        )}
      </li>
    </React.Fragment>
  );
};

export default ReplyListItem;
