import React from "react";
import { Fragment } from "react";
import AddCampsiteForm from "../components/Campsite/AddCampsite/AddCampsiteForm";
import { Container, Row, Col, Alert } from "react-bootstrap";
import CampsiteExample from "./CampingExample.png";
import CampsiteExample2 from "./CampingExample2.png";

const AddCampsite = () => {
  return (
    <Fragment>
      <Container fluid className="pr-3 pl-3">
        <h1 className="h1 mt-2">Add Campsite</h1>
        <Row>
          <Col sm={7} className="border-right p-2 border-dark">
            <AddCampsiteForm />
          </Col>
          <Col>
            <Alert variant="info">
              <h4 className="h4">Tips to Create a Perfect Listing</h4>
              <ul className="text-justify">
                <li>
                  Write a detailed description - Include as much as you can
                  about your campsite.
                </li>
                <li>Use Good Quality Images - Aim for 4-5 Images!</li>
              </ul>
              <h4 className="h4">Benefits of using Camper Planner</h4>
              <ul className="text-justify">
                <li>Increased online exposure</li>
                <li>Greater interaction with the road tripping community</li>
              </ul>

              <p>
                Users see campsites by location on the route planning map. When
                they click on the campsite all details will show!
              </p>
              <img
                alt=""
                className="img-fluid p-3 w-100"
                src={CampsiteExample}
              />
              <p></p>
              <img
                alt=""
                className="img-fluid p-3 w-100"
                src={CampsiteExample2}
              />
            </Alert>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddCampsite;
