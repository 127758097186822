import React, { useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import ForumRules from "../components/Forum/ForumRules";
import TopicList from "../components/Forum/TopicList";
import { FaPlusCircle } from "react-icons/fa";
import ReactDOM from "react-dom";
import AddTopicModal from "../components/Modal/Forum/AddTopicModal";
import UserActivity from "../components/Forum/UserActivity";
import InfoIcon from "../components/UI/InfoOverlay/InfoIcon";

const Forum = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Modal Visibility State
  const [showAddTopicModal, setShowAddTopicModal] = useState(false);
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <AddTopicModal
          show={showAddTopicModal}
          handleClose={() => setShowAddTopicModal(false)}
        />,
        document.getElementById("add-route-modal")
      )}
      <Container fluid className="mt-2">
        <div className="h-50">
          <Row>
            <Col>
              <h1 className="h1">Forum</h1>
            </Col>
            <Col>
              <p className="lead float-right">Interact with the community</p>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div>
                <Row className="mt-3">
                  <Col>
                    <h3 className="h3">Discussions</h3>
                  </Col>
                  {authCtx.role == 2 && (
                    <Col>
                      <Button
                        className="btn-primary btn-sm float-right"
                        onClick={() => setShowAddTopicModal(true)}
                      >
                        Add <FaPlusCircle />
                      </Button>
                    </Col>
                  )}
                </Row>
                <TopicList />
              </div>
            </Col>
            <Col sm={6}>
              <Alert variant="info">
                <ForumRules />
              </Alert>
            </Col>
          </Row>
        </div>
        <Col sm={12}>
          <h3 className="h3 m-2">
            My Activity{" "}
            <span>
              <InfoIcon message="Quick access to delete or view user activity." />
            </span>
          </h3>

          <UserActivity />
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default Forum;
