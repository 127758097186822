import React, { useRef, useState, useContext } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import CampsiteDetails from "../../ViewDetails/CampsiteDetails";
import AuthContext from "../../../store/auth-context";
import axios from "axios";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCampsite = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //loading spinner
  const [isLoading, setIsLoading] = useState(false);
  //Validation Errors
  const [validationErrors, setValidationErrors] = useState({});
  //Used to access the parameters in the URL
  const params = useParams();
  //Ref
  const notesRef = useRef();
  //State
  const [date, setDate] = useState(new Date());

  const addCampsiteRoutePoint = async (data) => {
    //Show spinner
    setIsLoading(true);
    //Reset Validation Errors
    setValidationErrors({});
    try {
      await axios.post(
        "https://api-roadtrip-resource.azure-api.net/v1/api/routepoint/add/campsite",
        data,
        authCtx.config
      );
      //Hide spinner
      setIsLoading(false);
      //Refresh API data
      authCtx.refreshData();
      //Toast Notification
      toast.success("Route Point Added!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });

      //Close the modal and toggle the campsite add button
      props.toggleCancelButton(() => false);
      props.handleClose();
    } catch (error) {
      // Handle Error
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);
      console.log(error.response);
      //Hide spinner
      setIsLoading(false);
    }
  };

  const formSubmit = (event) => {
    //Prevent refresh
    event.preventDefault();
    const notesValue = notesRef.current.value;
    //create data object with all data
    const data = {
      arrivalDate: date.toISOString(),
      campsiteId: props.campsite.id,
      routeId: params.routeId,
      notes: notesValue,
    };
    addCampsiteRoutePoint(data);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Add Campsite</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formSubmit}>
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <Form.Group controlId="formDate">
                  <Form.Label>Arrival Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onBlur={(event) => setDate(new Date(event.target.value))}
                    required
                  />
                  {validationErrors.ArrivalDate && (
                    <span className="text-danger">
                      {validationErrors.ArrivalDate[0]}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group as={Row} controlId="formDescription">
                  <Form.Label column sm="1">
                    Notes
                  </Form.Label>
                  <Col className="col-11">
                    <Form.Control as="textarea" rows={2} ref={notesRef} />
                    {validationErrors.Notes && (
                      <span className="text-danger">
                        {validationErrors.Notes[0]}
                      </span>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <CampsiteDetails campsite={props.campsite} />
          </Modal.Body>
          <Modal.Footer>
            {!isLoading && (
              <Button
                variant="secondary btn-sm"
                onClick={() => {
                  props.handleClose();
                  setValidationErrors({});
                }}
              >
                Close
              </Button>
            )}

            {isLoading && (
              <Spinner
                animation="border"
                variant="primary"
                className="float-right"
              />
            )}
            {!isLoading && (
              <Button variant="primary btn-sm" type="submit">
                Add
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddCampsite;
