import React, { useState } from "react";

//Helps with autocompletion
const AuthContext = React.createContext({
  token: "",
  config: "",
  role: "",
  username: "",
  setUsername: (username) => {},
  setRole: (role) => {},
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  refreshData: () => {},
  refresh: false,
});

export const AuthContextProvider = (props) => {
  //Get data from local storage
  const initialToken = localStorage.getItem("token");
  const initialRole = localStorage.getItem("role");
  const initialUsername = localStorage.getItem("username");
  //Config
  const initialConfig = {
    headers: {
      Authorization: `Bearer ${initialToken}`,
    },
  };

  const [token, setToken] = useState(initialToken);
  const [userRole, setUserRole] = useState(initialRole);
  const [username, setUsername] = useState(initialUsername);
  const [config, setConfig] = useState(initialConfig);
  const [refresh, setRefresh] = useState(false);
  const userIsLoggedIn = !!token;

  const refreshHandler = () => {
    setRefresh(!refresh);
  };

  const usernameHandler = (username) => {
    setUsername(username);
    //Store the username in local storage
    localStorage.setItem("username", username);
  };

  const roleHandler = (role) => {
    setUserRole(role);
    //Store the role in local storage
    localStorage.setItem("role", role);
  };

  const loginHandler = (token) => {
    setToken(token);
    setConfig({
      headers: { Authorization: `Bearer ${token}` },
    });
    //Store the token in local storage
    localStorage.setItem("token", token);
  };

  const logoutHandler = () => {
    //Remove locally stored data
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    //Clear state
    setToken(null);
    setConfig(null);
    setUserRole(null);
    setUsername(null);
  };

  //Create object storing all values to be accessed throughout the website
  const contextValue = {
    token: token,
    config: config,
    username: username,
    setUsername: usernameHandler,
    role: userRole,
    setRole: roleHandler,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    refresh: refresh,
    refreshData: refreshHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
