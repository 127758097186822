import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Map from "../components/RoutePoints/Map/Map";
import AuthContext from "../store/auth-context";
import axios from "axios";
import RoutePointListFooter from "../components/RoutePoints/RoutePointListFooter";
import RoutePointList from "../components/RoutePoints/RoutePointList";
import RoutePointListHeader from "../components/RoutePoints/RoutePointListHeader";

const ViewRoute = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Access to URL parameters
  const params = useParams();
  //Redirect
  const history = useHistory();
  //State
  const [campsites, setCampsites] = React.useState();
  const [campsitesLoaded, setCampsitesLoaded] = React.useState(false);
  const [routePointsLoaded, setRoutePointsLoaded] = useState(false);
  const [routePoints, setRoutePoints] = useState();
  const [route, setRoute] = useState();
  const [points, setPoints] = useState();
  //Button State
  const [cancelButton, setCancelButton] = useState(false);

  const buttonHandler = () => {
    setCancelButton(!cancelButton);
  };

  useEffect(() => {
    const getRoutePoints = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/route/getRoute/${params.routeId}`,
          authCtx.config
        );
        setRoute(resp.data);
        setRoutePoints(resp.data.routePoint);
        //Format points for polyline overlay
        let arry = [];
        resp.data.routePoint.forEach((routepoint) => {
          if (routepoint.campsite)
            arry.push([
              routepoint.campsite.longitude,
              routepoint.campsite.latitude,
            ]);
          if (routepoint.location)
            arry.push([
              routepoint.location.longitude,
              routepoint.location.latitude,
            ]);
        });
        setPoints(arry);
        setRoutePointsLoaded(true);
      } catch (error) {
        // Handle Error
        //Redirect to not found
        history.replace("/notfound");
        console.log(error.response);
      }
    };
    getRoutePoints();
  }, [authCtx.refresh, authCtx.config, history, params.routeId]);

  //Get data on page load or when refresh is toggled
  useEffect(() => {
    const getCampsites = async (data) => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/getcampsites`,
          authCtx.config
        );
        setCampsites(resp.data);
        setCampsitesLoaded(true);
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    getCampsites();
  }, [authCtx.config]);

  return (
    <div style={{ height: "91vh" }}>
      <div className="container-fluid h-100 d-inline-block w-100 ">
        <div className="row h-100">
          <div className="col-4 h-100">
            {route && <RoutePointListHeader route={route} />}

            {routePointsLoaded && <RoutePointList routePoints={routePoints} />}
            <RoutePointListFooter
              buttonClick={buttonHandler}
              cancelButton={cancelButton}
            />
          </div>
          {routePointsLoaded && (
            <div className="col-8">
              {campsitesLoaded && (
                <Map
                  showMarker={cancelButton}
                  routePoints={routePoints}
                  campsites={campsites}
                  points={points}
                  toggleCancelButton={setCancelButton}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewRoute;
