import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { Col, Row } from "react-bootstrap";
import UserUpvoteItem from "./UserUpvoteItem";
import axios from "axios";
import PaginationDisplay from "../UI/Pagination/PaginationDisplay";

const UserUpvotes = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Upvoted Post Data
  const [upvotedPosts, setUpvotedPosts] = useState();
  //Pagination State
  const pageSize = 4;
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  //Set the pagination page
  const pageNumberHandler = (data) => {
    setPageNumber(data);
  };

  //Get upvoted posts on page load, when page number state changes and when the global refresh has been toggled
  useEffect(() => {
    const getUserUpvotedPosts = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/forum/user/posts/upvoted?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          authCtx.config
        );
        //Store the upvoted posts data
        setUpvotedPosts(resp.data);
        //Store the pagination metadata from the header
        setPaginationData(JSON.parse(resp.headers["x-pagination"]));
      } catch (error) {
        //Log error
        console.log(error.response);
      }
    };
    getUserUpvotedPosts();
  }, [authCtx.refresh, pageNumber, authCtx.config]);

  return (
    <React.Fragment>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <Row>
            <Col sm={1}></Col>
            <Col sm={10} className="font-weight-bold">
              Posts
            </Col>
            <Col sm={1}></Col>
          </Row>
        </li>
        {upvotedPosts &&
          upvotedPosts.map((item) => (
            <UserUpvoteItem
              key={item.id}
              post={item.post}
              setSelectedPage={pageNumberHandler}
            />
          ))}
      </ul>
      <div className="d-flex justify-content-center">
        {paginationData && (
          <PaginationDisplay
            pagination={paginationData}
            pageSelected={pageNumberHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default UserUpvotes;
