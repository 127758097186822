import React, { useState, useContext } from "react";
import { BsTrashFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import EditRouteModal from "../Modal/Route/EditRouteModal";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import DeleteConfirmModal from "../Modal/DeleteConfirmModal";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Row } from "react-bootstrap";
import { BsChevronRight } from "react-icons/bs";

toast.configure();

const RouteListItem = (props) => {
  //Global State
  const authCtx = useContext(AuthContext);
  //Modal Visibility State
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRouteAccessDeleteConfirm, setShowRouteAccessDeleteConfirm] =
    useState(false);

  const deleteRoute = async (data) => {
    try {
      await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/route/deleteroute/${data}`,
        authCtx.config
      );
      //Reload API data
      authCtx.refreshData();
      //Toast Notification
      toast.success("The route has been deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      // Handle Error
      console.log(error.response);
    }
  };

  const removeRouteAccess = async () => {
    try {
      const resp = await axios.delete(
        `https://api-roadtrip-resource.azure-api.net/v1/api/route/removeRouteAccess?RouteId=${props.route.id}&Username=${authCtx.username}`,
        authCtx.config
      );
      //Toast notification
      toast.success(resp.data.success, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      //Reload API data
      authCtx.refreshData();
    } catch (error) {
      //Handle Error
      error.response &&
        error.response.data &&
        toast.warning(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: true,
        });
      console.log(error.response);
    }
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <EditRouteModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          route={props.route}
        />,
        document.getElementById("edit-route-modal")
      )}
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showDeleteConfirm}
          handleClose={() => setShowDeleteConfirm(false)}
          header={"Delete Road Trip"}
          message={
            props.route.id &&
            `Are you sure you want to delete road trip ${props.route.title}?`
          }
          delete={() => deleteRoute(props.route.id)}
        />,
        document.getElementById("delete-confirm-modal")
      )}
      {ReactDOM.createPortal(
        <DeleteConfirmModal
          show={showRouteAccessDeleteConfirm}
          handleClose={() => setShowRouteAccessDeleteConfirm(false)}
          header={"Leave Route"}
          message={
            props.route.id &&
            `Are you sure you want to leave the route -  ${props.route.title}?`
          }
          delete={() => removeRouteAccess()}
        />,
        document.getElementById("delete-confirm-modal")
      )}
      <li key={props.route.id} className="list-group-item">
        <Row>
          <Col sm={7}>
            <p className="lead mt-2"> {props.route.title}</p>
          </Col>

          <Col sm={5}>
            <Link
              className="btn text-secondary float-right"
              to={`/routes/${props.route.id}`}
            >
              <BsChevronRight color="black" className="mb-2" />
            </Link>
            {/* If the user does not own the route show the leave button */}
            {props.route.username !== authCtx.username && (
              <span>
                <Button
                  variant="danger btn-sm float-right mr-3 mt-1 mb-2"
                  onClick={() => setShowRouteAccessDeleteConfirm(true)}
                >
                  Leave
                </Button>
              </span>
            )}
            {/* If the user owns the route show the delete and edit icons */}
            {props.route.username == authCtx.username && (
              <span>
                <BsTrashFill
                  role="button"
                  className="text-danger float-right m-2"
                  onClick={() => setShowDeleteConfirm(true)}
                />
                <FaEdit
                  role="button"
                  className="text-secondary float-right m-2 mr-3"
                  onClick={() => setShowEditModal(true)}
                />
              </span>
            )}
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};

export default RouteListItem;
