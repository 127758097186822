import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import axios from "axios";

const Details = () => {
  //Global State
  const authCtx = useContext(AuthContext);
  //User details state
  const [userDetails, setUserDetails] = useState();

  //Reload user data when API refresh toggled
  useEffect(() => {
    const getUser = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/user/getuser?username=${authCtx.username}`,
          authCtx.config
        );
        //Store user details
        setUserDetails(resp.data);
      } catch (error) {
        // Handle Error
        console.log(error.response);
      }
    };
    getUser();
  }, [authCtx.refresh, authCtx.username, authCtx.config]);

  return (
    <React.Fragment>
      {userDetails && (
        <Row className="p-3">
          <dt className="col-3">
            <p>Username </p>
          </dt>
          <dd className="col-9">{userDetails.username}</dd>

          <dt className="col-3">
            <p>Email </p>
          </dt>
          <dd className="col-9">{userDetails.email}</dd>

          <dt className="col-3">
            <p>User Type </p>
          </dt>
          <dd className="col-9">
            {userDetails.role == 0 && "User"}
            {userDetails.role == 1 && "Campsite User"}
            {userDetails.role == 2 && "Admin"}
          </dd>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Details;
