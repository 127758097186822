import React, { useRef, useState, useContext, useEffect } from "react";
import LocationInputAuto from "../../UI/LocationInputAuto";
import { Form, Button, Col, Row } from "react-bootstrap";
import CampsiteLocationMap from "../CampsiteMap/CampsiteLocationMap";
import { useHistory, useParams } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const EditCampsiteForm = () => {
  //global state
  const authCtx = useContext(AuthContext);

  //validation
  const [validationErrors, setValidationErrors] = useState({});

  //history enables redirect using react router and params allows to get the ID from the url
  const history = useHistory();
  const params = useParams();

  //Campsite details
  const [campsite, setCampsite] = useState(null);

  //Address state
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [address, setAddress] = useState("");

  //Refs
  const titleRef = useRef();
  const descriptionRef = useRef();
  const wcRef = useRef();
  const dogFriendlyRef = useRef();
  const electricRef = useRef();
  const wifiRef = useRef();
  const chemicalDisposalRef = useRef();
  const waterPointRef = useRef();
  const webAddressRef = useRef();
  const telNoRef = useRef();
  const emailRef = useRef();
  const highSeasonRef = useRef();
  const lowSeasonRef = useRef();

  const locationHandler = (placeId) => {
    //get the longitude and latitude from the place id using google geocoding
    geocodeByPlaceId(placeId)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLongitude(lng);
        setLatitude(lat);
      });

    //get the address from the placeId
    geocodeByPlaceId(placeId)
      .then((results) => setAddress(results[0].formatted_address))
      .then((error) => console.log(error));
  };

  const updateCampsite = async (data) => {
    //Reset validation errors
    setValidationErrors({});
    try {
      await axios.put(
        `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/update/${params.campsiteId}`,
        data,
        authCtx.config
      );
      //Redirect to campsites page
      history.replace("/campsites");
    } catch (error) {
      //set error messages
      error.response.data &&
        error.response.data.errors &&
        setValidationErrors(error.response.data.errors);

      console.log(error.response);
    }
  };

  const submitHandler = (event) => {
    //Prevent page refresh on submit
    event.preventDefault();
    //Get all form data
    const titleValue = titleRef.current.value;
    const descriptionValue = descriptionRef.current.value;
    const wcValue = wcRef.current.checked;
    const dogFriendlyValue = dogFriendlyRef.current.checked;
    const electricValue = electricRef.current.checked;
    const wifiValue = wifiRef.current.checked;
    const chemicalDisposalValue = chemicalDisposalRef.current.checked;
    const waterPointValue = waterPointRef.current.checked;
    const webAddressValue = webAddressRef.current.value;
    const telNoValue = telNoRef.current.value;
    const emailValue = emailRef.current.value;
    const highSeasonValue = highSeasonRef.current.value;
    const lowSeasonValue = lowSeasonRef.current.value;

    //Add all values to data object
    const data = {
      title: titleValue,
      description: descriptionValue,
      longitude,
      latitude,
      address,
      highSeasonCost: highSeasonValue,
      lowSeasonCost: lowSeasonValue,
      wc: wcValue,
      dogFriendly: dogFriendlyValue,
      electric: electricValue,
      wifi: wifiValue,
      chemicalDisposal: chemicalDisposalValue,
      drinkingWaterPoint: waterPointValue,
      contactEmail: emailValue,
      contactTelNo: telNoValue,
      webAddress: webAddressValue,
    };

    updateCampsite(data);
  };

  useEffect(() => {
    //Run method on page load
    const getCampsite = async () => {
      try {
        const resp = await axios.get(
          `https://api-roadtrip-resource.azure-api.net/v1/api/campsite/getcampsite/${params.campsiteId}`,
          authCtx.config
        );
        //Set campsite state
        setCampsite(resp.data);
        setAddress(resp.data.address);
        setLatitude(resp.data.latitude);
        setLongitude(resp.data.longitude);
      } catch (error) {
        //Redirect to not found if request was unsuccessful
        history.replace("/notfound");
      }
    };
    getCampsite();
  }, [authCtx.config, params.campsiteId, history]);

  return (
    <React.Fragment>
      {campsite && (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              defaultValue={campsite.title}
              ref={titleRef}
              placeholder="Campsite name"
            />
            {validationErrors.Title && (
              <span className="text-danger">{validationErrors.Title[0]}</span>
            )}
          </Form.Group>

          <Form.Group controlId="address">
            <Row>
              <Col sm={6}>
                <div>Address:</div>
                <div>{address}</div>
                {longitude} {latitude}
                {validationErrors.Address && (
                  <span className="text-danger">
                    {validationErrors.Address[0]}
                  </span>
                )}
              </Col>
              <Col sm={6}>
                Change Address
                <LocationInputAuto setData={locationHandler} />
              </Col>
            </Row>
          </Form.Group>

          <Row>
            <Col sm={8}>
              <Form.Group controlId="formDescription">
                <Form.Label>Brief Description</Form.Label>
                <Form.Control
                  defaultValue={campsite.description}
                  ref={descriptionRef}
                  as="textarea"
                  rows={8}
                />
                {validationErrors.Description && (
                  <span className="text-danger">
                    {validationErrors.Description[0]}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col sm={4}>
              {longitude && latitude && (
                <CampsiteLocationMap
                  longitude={longitude}
                  latitude={latitude}
                />
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={6}>
              <Form.Group>
                <Form.Label>High Season Average Cost per Day (£)</Form.Label>
                <Form.Control
                  ref={highSeasonRef}
                  defaultValue={campsite.highSeasonCost}
                  placeholder="Cost in £"
                  type="number"
                  min="0.00"
                  step=".01"
                />
                {validationErrors.HighSeasonCost && (
                  <span className="text-danger">
                    {validationErrors.HighSeasonCost[0]}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Low Season Average Cost per Day(£)</Form.Label>
                <Form.Control
                  ref={lowSeasonRef}
                  defaultValue={campsite.lowSeasonCost}
                  placeholder="Cost in £"
                  type="number"
                  min="0.00"
                  step=".01"
                />
                {validationErrors.LowSeasonCost && (
                  <span className="text-danger">
                    {validationErrors.LowSeasonCost[0]}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Form.Label className="mb-3">Facilities</Form.Label>

          <Form.Row>
            <Col>
              <Form.Check
                defaultChecked={campsite.wc}
                ref={wcRef}
                type="checkbox"
                label="WC"
              />
            </Col>
            <Col>
              <Form.Check
                defaultChecked={campsite.dogFriendly}
                ref={dogFriendlyRef}
                type="checkbox"
                label="Dog Friendly"
              />
            </Col>
            <Col>
              <Form.Check
                defaultChecked={campsite.electric}
                ref={electricRef}
                type="checkbox"
                label="Electric"
              />
            </Col>
            <Col>
              <Form.Check
                defaultChecked={campsite.wifi}
                ref={wifiRef}
                type="checkbox"
                label="Wifi"
              />
            </Col>
            <Col>
              <Form.Check
                defaultChecked={campsite.chemicalDisposal}
                ref={chemicalDisposalRef}
                type="checkbox"
                label="Chemical disposal"
              />
            </Col>
            <Col>
              <Form.Check
                defaultChecked={campsite.drinkingWaterPoint}
                ref={waterPointRef}
                type="checkbox"
                label="Drinking Water Point"
              />
            </Col>
          </Form.Row>

          <Row className="mt-3">
            <Col sm={6}>
              <Form.Group controlId="webaddress">
                <Form.Label>Web Address</Form.Label>
                <Form.Control
                  defaultValue={campsite.webAddress}
                  ref={webAddressRef}
                  placeholder="Enter Web Address"
                  type="url"
                />
                {validationErrors.WebAddress && (
                  <span className="text-danger">
                    {validationErrors.WebAddress[0]}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="webaddress">
                <Form.Label>Contact Telephone No</Form.Label>
                <Form.Control
                  defaultValue={campsite.contactTelNo}
                  ref={telNoRef}
                  placeholder="Enter Tel No"
                  type="tel"
                />
                {validationErrors.ContactTelNo && (
                  <span className="text-danger">
                    {validationErrors.ContactTelNo[0]}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  defaultValue={campsite.contactEmail}
                  ref={emailRef}
                  placeholder="Enter Email"
                  type="email"
                />
                {validationErrors.ContactEmail && (
                  <span className="text-danger">
                    {validationErrors.ContactEmail[0]}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Button className="float-right mt-3" variant="primary" type="submit">
            Update
          </Button>
        </Form>
      )}
    </React.Fragment>
  );
};

export default EditCampsiteForm;
