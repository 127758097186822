import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const LocationInputAuto = ({ setData }) => {
  //Controlled state
  const [value, setValue] = useState(null);

  useEffect(() => {
    //Set the placeId of the location in the parent component
    value && setData(value.value.place_id);
    // eslint-disable-next-line
  }, [value]);

  return (
    <div>
      <GooglePlacesAutocomplete
        selectProps={{
          value,
          onChange: setValue,
        }}
      />
    </div>
  );
};

export default LocationInputAuto;
